import { combineReducers } from 'redux-immutable';
import { routerReducer } from 'react-router-redux';
import main from './main';
import appoint from './appoint';
import channel from './channel';
import product from './product';
import coupon from './coupon';
import message from './message';
import order from './order';
import deliverySafeguard from './deliverySafeguard';
import frieghtInsurance from './frieghtInsurance';
import routing from './routing';
import pcInfo from './pcInfo';

// 生成一个假的reducer
const rootReducer = combineReducers({
  main,
  appoint,
  channel,
  product,
  coupon,
  message,
  order,
  deliverySafeguard,
  frieghtInsurance,
  routing,
  pcInfo
});

export default rootReducer;
