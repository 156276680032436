import { FETCH_REQUEST, POST_PAY_DATA, SWITCH_ITEMS_REQUEST, SWITCH_ITEMS_SUCCESS, SWITCH_ITEMS_FAILURE } from '../constants/actionTypes';
import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';
import { ROOT_DOMAIN, BAO_XIAN_MEMBER_QUERY, USER_CHECK_LOGIN, QUERY_RECOMMEND_NO, QUERY_SWITCH_ID } from '../constants/api';
import { redirectPassport } from '../common/js/redirect';
import { sendMessage } from './message';
export { sendMessage }; // temp

//设置当前分享信息
export function saveRecomKey(key, itemId = '') {
  return async (dispatch, getState) => {
    if (!key) {
      fetchSwitchItems(itemId)
      return;
    }
    try {
      let response = await fetch(`${BAO_XIAN_MEMBER_QUERY}?hserecomkey=${key}`);
      let json = await response.json();
      let uid = '', empNo = ''
      if (json.success) {
        // 设置cookie, 分享有效期7天
        Cookies.set('hserecomkey', key, {
          expires: 7,
          path: '/',
          domain: ROOT_DOMAIN
        });
        // 查询uid是否为工号
        uid = json.member_id
        let res = await fetch(`${QUERY_RECOMMEND_NO}?uid=${json.member_id}`)
        let data = await res.json()
        if (+data.errno === 0) {
          if (data.data.empNo) {
            empNo = data.data.empNo
            Cookies.set('recommend_member_id', data.data.empNo, {
              expires: 7,
              path: '/',
              domain: ROOT_DOMAIN
            });
          }
        } else {
          Cookies.set('recommend_member_id', json.member_id, {
            expires: 7,
            path: '/',
            domain: ROOT_DOMAIN
          });
        }
        Cookies.set('recommend_time', new Date().getTime(), {
          expires: 7,
          path: '/',
          domain: ROOT_DOMAIN
        });
      }
      fetchSwitchItems(itemId, uid, empNo)
    } catch (e) {
      console.log('key err', e)
      // if (__CLIENT__) {
        window.Raven && window.Raven.captureException(e);
      // }
      dispatch(sendMessage('查询服务异常, 请稍后再试!'));
    }
  }
};

async function fetchSwitchItems (itemId, uid='', empNo='') {
  let query = await fetch(`${QUERY_SWITCH_ID}`)
  let queryInfo = await query.json()
  const list = queryInfo.data.list
  for (let item of list) {
    const params = uid || empNo ? `&shareKey=${uid}&recomKey=${empNo}` : ''
    if (item.oldId == itemId) {
      // eslint-disable-next-line no-restricted-globals
      location.href = `https://finance.xingbeibaoxian.com/insurance/transfer?needApi=1&itemId=${itemId}`
    }
  }
}

//判断登录态
export function checkLogin(data={}) {
  // if (__SERVER__) {
  //   return;
  // }
  return async (dispatch, getState) => {
    if (data.uid && data.skey) {
      Cookies.set('uid', data.uid, {
        path: '/',
        domain: '.cekid.com',
        expires: 7
      })
      Cookies.set('skey', data.skey, {
        path: '/',
        domain: '.cekid.com',
        expires: 7
      })
    }
    let uid = data.uid || Cookies.get('uid'),
      skey = data.skey || Cookies.get('skey');
    if (!uid || !skey) {
      return redirectPassport();
    }
    try {
      //判断是否登录
      let response = await fetch(`${USER_CHECK_LOGIN}?uid=${uid}&skey=${skey}`);
      let json = await response.json();
      if (json.errno != 0) {
        //用户未登录, 跳转登录
        return redirectPassport();
      } else {
        return 1
      }
    } catch (e) {
      console.log('err', e)
      // if (__CLIENT__) {
        window.Raven && window.Raven.captureException(e);
      // }
      dispatch(sendMessage('服务异常, 请稍后再试!'));
    }
  }
};
