import { Map } from 'immutable';
import { CHANNEL_DATA_REQUEST, CHANNEL_DATA_SUCCESS, CHANNEL_DATA_FAILURE } from '../constants/actionTypes';

const initialState = Map();
export default function channel(state = initialState, action) {
  switch (action.type) {
    case CHANNEL_DATA_SUCCESS:
      let data = {};
      data[action.id] = action.data;
      return state.merge(data);
    default:
      return state;
  }
}
