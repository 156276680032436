import { Map, fromJS } from 'immutable';
import { MAIN_DATA_REQUEST, MAIN_DATA_SUCCESS, MAIN_DATA_FAILURE } from '../constants/actionTypes';

const initialState = Map();

export default function main(state = initialState, action) {
  switch (action.type) {
    case MAIN_DATA_SUCCESS:
      return fromJS(action.data || {});

    default:
      return state;
  }
}
