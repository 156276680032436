import Cookies from 'js-cookie';
import { push } from 'react-router-redux';
import {
  CURRENT_CITY_DATA_LOADED,
  CITY_ADVISER_DATA_LOADED,
  APPOINT_FETCH_REQUEST,
  APPOINT_RESET_FETCH,
  MODYFY_CURRENT_CITY
} from '../constants/actionTypes';
import {
  IP_SEARCH_DETAIL,
  CMS_CITY_ADVISER,
  CMS_NEIBU_CITY_ADVISER,
  BAO_XIAN_QUERY_LAST_LOCATION,
  BAO_XIAN_SAVE_LAST_LOCATION,
  BAO_XIAN_SAVE_RESERVATION
} from '../constants/api';
import fetch from 'isomorphic-fetch';
import { param } from '../common/js/utility';
import { redirectPassport } from '../common/js/redirect';
import { sendMessage } from './message';

// 当前城市位置数据加载完成
function currentCityDataLoaded(data) {
  return {
    type: CURRENT_CITY_DATA_LOADED,
    data: data
  }
}

// 城市顾问数据加载完成
function cityAdviserDataLoaded(data) {
  return {
    type: CITY_ADVISER_DATA_LOADED,
    data: data
  }
}

//数据请求
function appointFetchRequest() {
  return {
    type: APPOINT_FETCH_REQUEST
  };
}

//重置fetch状态
export function appointResetFetch() {
  return {
    type: APPOINT_RESET_FETCH
  }
}

//识别当前城市
export function fetchCurrentCity() {
  return async (dispatch, getState) => {
    try {
      let response = await fetch(IP_SEARCH_DETAIL);
      let json = await response.json();
      if (json.success && json.content && json.content.result && json.content.result.city) {
        dispatch(currentCityDataLoaded(json.content.result.city.replace('市', '')));
      } else {
        dispatch(sendMessage(json.msg));
      }
    } catch (e) {
      // console.log('actions->fetchCurrentCity error: ' + e);
      dispatch(sendMessage('服务异常, 请稍后再试!'));
    }
  }
};

//城市顾问配置数据加载
export function fetchCityAdviser() {
  return async (dispatch, getState) => {
    try {
      if (getState().getIn(['appoint', 'adviserList']) && getState().getIn(['appoint', 'cityList'])) {
        return;
      }
      let response = await fetch(CMS_CITY_ADVISER);
      let json = await response.json();
      dispatch(cityAdviserDataLoaded(json.data));
    } catch (e) {
      console.error('fetch CityAdviser error: ', e);
      dispatch(sendMessage('服务异常, 请稍后再试!'));
    }
  }
};

//修改当前城市
export function modifyCurrentCity(cityName) {
  return {
    type: MODYFY_CURRENT_CITY,
    data: cityName
  }
}

export function getCityForAdviser() {
  return async (dispatch, getState) => {
    if (getState().getIn(['appoint', 'isFetching'])) {
      return;
    }
    let uid = Cookies.get('uid'),
     skey = Cookies.get('skey') ;
    dispatch(appointFetchRequest());
    try {
      let response = await fetch(`${BAO_XIAN_QUERY_LAST_LOCATION}?uid=${uid}&skey=${skey}`);
      let json = await response.json();
      if (json.success && json.data) {
        //获取成功
        dispatch(modifyCurrentCity(json.data.cityName));
      } else if(!json.success && json.errno === 1024) { //未登录
        return redirectPassport();
      } else {
        dispatch(modifyCurrentCity('NULL'));
      }
    } catch (e) {
      // console.log('actions->getCityForAdviser error: ' + e);
      dispatch(sendMessage('服务异常, 请稍后再试!'));
    }
  }
}

//选择保险顾问的城市
export function selectCityforAdviser(cityName) {
  return async (dispatch, getState) => {
    if (getState().getIn(['appoint', 'isFetching'])) {
      return;
    }
    let uid = Cookies.get('uid'),
     skey = Cookies.get('skey') ;
    dispatch(appointFetchRequest());
    try {
      let response = await fetch(`${BAO_XIAN_SAVE_LAST_LOCATION}?cityName=${cityName}&uid=${uid}&skey=${skey}`);
      let json = await response.json();
      if (json.success) {
        //选择成功
        dispatch(modifyCurrentCity(cityName));
        dispatch(push('/adviser'));
      } else if(!json.success && json.errno === 1024) { //未登录
        return redirectPassport();
      } else {
        dispatch(sendMessage(json.msg));
      }
    } catch (e) {
      // console.log('actions->selectCityforAdviser error: ' + e);
      dispatch(sendMessage('服务异常, 请稍后再试!'));
    }
  }
}

//预约育儿顾问
export function appointAdviser(opt) {
  return async (dispatch, getState) => {
    // const {currentCity, adviserList} = getState().appoint;
    const currentCity = getState().getIn(['appoint', 'currentCity']);
    const adviserList = getState().getIn(['appoint', 'adviserList']).toJS();    // todo
    if (!currentCity) {
      return dispatch(sendMessage('请先选择您的城市！'));
    }
    for (let i = 0; i < adviserList.length; i++) {
      if (adviserList[i].city == currentCity) {
        opt.consultantId = adviserList[i].eid;
        opt.consultantName = adviserList[i].name;
        opt.consultantPhone = adviserList[i].phone;
        opt.cityName = adviserList[i].city;
        break;
      }
    }
    if (!opt.cityName) {
      return dispatch(sendMessage('您选择的城市目前没有保险顾问！'));
    }
    opt.uid = Cookies.get('uid');
    opt.skey = Cookies.get('skey');
    try {
      let response = await fetch(BAO_XIAN_SAVE_RESERVATION, {
          method: 'POST',
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
          },
          body: param(opt)
        });
      let json = await response.json();
      if (json.success) {
        //预约成功
        dispatch(push('/appointment/success'));
      } else {
        dispatch(sendMessage(json.msg));
      }
    } catch (e) {
      // console.log('actions->appointAdviser error: ' + e);
      dispatch(sendMessage('服务异常, 请稍后再试!'));
    }
  }
}
