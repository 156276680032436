import Cookies from 'js-cookie';
import { push } from 'react-router-redux';
import {
  ACTIVITY_QUALIFY_REQUEST,
  ACTIVITY_QUALIFY_FAILURE,
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_RESET,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAILURE,
  ORDER_DETAIL_SUCCESS,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAILURE,
  ORDER_CANCEL_SUCCESS,
  ORDER_SUCCESS_PRODUCT,
  CLAIM_OTAIN_REQUEST,
  CLAIM_OTAIN_SUCCESS,
  CLAIM_OTAIN_FAIL,
  REQUEST_CLAIM_DETAIL,
  CLAIM_DETAIL_SUCCESS,
  CLAIM_DETAIL_FAILURE,
  DELETE_APPLY_CLAIM,
  SUBMIT_CAR_INSURANCE,
  CAR_INSURANCE_ORDER_SUCCESS,
  CAR_INSURANCE_ORDER_FAILURE,
  SUBMIT_CAR_INSURANCE_ORDER_LIST,
  CAR_INSURANCE_ORDER_LIST_SUCCESS,
  CAR_INSURANCE_ORDER_LIST_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  CHECK_BINDING_QUALIFY,
  CHECK_BINDING_QUALIFY_SUCCESS,
  CHECK_BINDING_QUALIFY_FAILURE,
  APPLY_CLAIM_CMS_SUCCESS,
  APPLY_CLAIM_REQUEST,
  APPLY_CLAIM_SUCCESS,
  APPLY_CLAIM_FAIL,
} from '../constants/actionTypes'
import { TAIKANG_ONLINE as types } from '../constants/actionTypes'
import {
  BAO_XIAN_ACTIVITY_QUALIFY,
  BAO_XIAN_ORDER_LIST,
  BAO_XIAN_ORDER_CREATE,
  BAO_XIAN_ORDER_DETAIL,
  BAO_XIAN_ORDER_PAY,
  BAO_XIAN_ORDER_REFUND,
  BAO_XIAN_ORDER_COMMENT,
  BAO_XIAN_ORDER_CANCEL,
  BAO_XIAN_DELETE_ORDER,
  CMS_ORDER_SUCCESS_PRODUCT,
  BAO_XIAN_ORDER_RENEWAL,
  CLAIM_OBTAIN_SUBMIT,
  API_FETCH_CLAIM_DETAIL,
  CAR_INSURANCE_ORDER,
  CAR_INSURANCE_ORDER_LIST,
  INSURANCE_BINDING_QUALIFY,
  APPLY_CLAIM_CONFIG,
  APPLY_CLAIM_SUBMIT,
  TAIKANG_ONLINE_API
} from '../constants/api'
import fetch from '../common/js/fetch'
import {
  getPayType,
  getPlatform,
  param,
  standardPost 
} from '../common/js/utility'
import { redirectPassport } from '../common/js/redirect'
import { sendMessage } from './message'

//提交第三支付
function paySubmit(opt) {
  if (opt.hzwPayFlag != undefined && opt.hzwPayFlag == 0) {
    // 跳转保险自家收银台
    window.location.href = `//cashier.cekid.com/payment/insurance?orderid=${opt.orderId}&partnerid=102`
    return;
  }
  if (opt.flag == 0) {
    // get方式
    window.location.href = opt.payUrl;
  } else {
    // post方式
    standardPost(opt.payUrl || opt.payData.payUrl, opt.payData, 'POST')
  }
}

// 投保页面, 订单创建并提交
export function createOrder(info, notice) {
  // 投保者证件有身份证 外国人永久居住证，不适合判断了
  // if (!isCertificateNo(info.commonInsureInfo.insureIdCard)) {
  //   return dispatch => {
  //     dispatch(sendMessage('投保人身份证格式有误！'));
  //   }
  // }
  // 校验未成年
  const birth = info.commonInsureInfo.insureIdCard.match(/^\d{6}(\d{4})(\d{2})(\d{2})/).splice(1, 4);
  birth[0] = (+birth[0]) + 18;
  if (+new Date(birth.join('-')) > Date.now()) {
    return dispatch => {
      dispatch(sendMessage('投保人要求为成年人，请重新填写投保人信息'));
    }
  }
  info.orderChannel = getPlatform(); // 0IOS 1安卓 2微信 3H5
  const appCode = Cookies.get('appcode')
  if (appCode == 'czj100') {
    if (info.orderChannel == 0) {
      info.orderChannel = 5 // 成长加+ios
    } 
    if (info.orderChannel == 1) {
      info.orderChannel = 6  // 成长加+安卓
    }
  }
  info.orderType = info.orderType || 0; //0普通订单 1会员权益 2商城赠送 3免费赠险 4续保流程
  info.payFlag = (info.orderChannel == 2 ? 0 : 1); // 支付类型 0微信支付 1app支付
  info.hserecomkey = Cookies.get('sharekey') || ''
  if (info.hserecomkey == undefined || info.hserecomkey == 'undefined') {
    info.hserecomkey = ''
  }
  return {
    types: [ORDER_FETCH_REQUEST, (dispatch, getState, json) => {
      // dispatch({type: ORDER_CREATE_SUCCESS});

      try {
      Cookies.set('skuId', info.skuId, {
        path: '/',
        domain: 'insurance.cekid.com',
        expires: new Date(new Date().getTime() + 60 * 60 * 1000) // 过期时间1个小时
      })
      } catch (error) {
        console.error(error)
      }

      try {
        if (notice == 1) {
          // 支付成功后防止下次提交，无健康告知答案的产品带上了上一次产品告知答案
          window.localStorage && window.localStorage.removeItem('answer');
        }
      } catch (error) {
        console.error(error)
      }

      if (json.data.flag == 2) {
        // 富文本方式
        if (json.errno == 0) {
          // 支付页面
        try {
          window.localStorage.setItem('payHtml', json.data.payHtml);
        } catch (error) {
          console.error(error)
        }
          dispatch(push(`/pay`))
        } else {
          return sendMessage('支付异常，请重新购买')
        }
      } else if (json.data.flag == 3) {
        standardPost(json.data.payUrl, json.data.paramJson, 'POST')
      } else if (json.data.flag == 4) {
        standardPost(json.data.payUrl, json.data.paramJson, 'GET')
      } else {
        paySubmit(json.data); //拉起支付
      } 
      dispatch({
        type: ORDER_FETCH_RESET
      })
    }, ORDER_FETCH_RESET],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(`${BAO_XIAN_ORDER_CREATE}`, {
      timeout: 60000,
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      },
      credentials: 'include',
      body: param({data: JSON.stringify(info)})
    })
  };
}

// 获取订单号并提交核保信息
export function fetchProductActivityQualifycation(itemId, inventoryId, orderInfo) {
  return {
    types: [ACTIVITY_QUALIFY_REQUEST, (dispatch, getState, json) => {
      if (json.errno === '0') {
        orderInfo.seckill_qualification = json.data.qualification; // 把请求的资格号放入核保定单中
        dispatch({ // 获取资格号以后需要把isFetching更改回false
          type: ORDER_FETCH_RESET
        })
        dispatch(createOrder(orderInfo));
      } else if (json.errno === '1024') {
        redirectPassport(); // 验证登录
      } else {
        dispatch(sendMessage(json.errmsg, 0)); // 提示报错详细信息
      }
    }, ACTIVITY_QUALIFY_FAILURE],
    shouldCallAPI: getState => !getState().getIn(['product', 'list', itemId, 'activityQuantify']),
    callAPI: () => fetch(`${BAO_XIAN_ACTIVITY_QUALIFY}?itemId=${itemId}&inventoryId=${inventoryId}`,{
      credentials: 'include'
    }),
    itemId
  }
}

 // 重置订单fetch状态
 export function orderResetFetch() {
   return {
     type: ORDER_FETCH_RESET
   }
 }
 export function resetOrderFetch() {
   return {
     type: ORDER_FETCH_RESET
   }
 }

// 订单列表查询
export function fetchOrderList() {
  return {
    types: [ORDER_FETCH_REQUEST, ORDER_LIST_SUCCESS, ORDER_LIST_FAILURE],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(`${BAO_XIAN_ORDER_LIST}?type=0`, {
        credentials: 'include'
      })
  };
}

export function deleteOrder(orderId) {
  return {
    types: [DELETE_ORDER_REQUEST, (dispatch, getState, json) => {
      dispatch({
        type: DELETE_ORDER_SUCCESS
      })
      dispatch(fetchOrderList())
    }, DELETE_ORDER_FAILURE],
    callAPI: () => fetch(`${BAO_XIAN_DELETE_ORDER}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      },
      credentials: 'include',
      body: param({orderId: orderId})
    })
  };
}

// 再次购买，跳转商祥
export function redirectProductDetail(itemId, businessId) {
  return (dispatch) => {
    dispatch(push(`/product/${itemId}`));
  }
}

// 订单查看
export function redirectOrderDetail(orderId, userId) {
  return (dispatch) => {
    dispatch(push(`/order/detail/${orderId}?userId=${userId}`));
  }
}

// 立即评价 跳转
export function redirectOrderComment(orderId){
  return (dispatch) => {
    dispatch(push(`/order/comment/${orderId}`));
  }
}

// 续保 跳转
export function redirectOrderRenewal(itemId, orderId, skuId, businessId, renewalPayPrice){
  // 平安少儿重大疾病险续保skuId变化为1214 统一改为后端控制虚报skuId
  // skuId = skuId == 1067 ? 1214 : skuId;
  // renewalPayPrice 续保费用供续保页面使用
  window.localStorage && window.localStorage.setItem('renewalPayPrice', renewalPayPrice);
  return {
    types: [ORDER_FETCH_REQUEST, (dispatch, getState, json) => {
      dispatch(resetOrderFetch());
      if (json.data.healthFlag == 0 && businessId != 11) {
        // 需要健康告知的非慧择产品,带有新的投保价格
        return dispatch(push(`/product/notice/${itemId}/${skuId}?orderId=${orderId}&renewalpayprice=${renewalPayPrice}`));
      } else {
        // 无需健康告知所有产品,带有新的投保价格
        return dispatch(push(`/order/renew/${itemId}/${skuId}/${orderId}?renewalpayprice=${renewalPayPrice}`));
      }
    }, ORDER_FETCH_RESET],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(`${BAO_XIAN_ORDER_RENEWAL}?orderId=${orderId}&skuId=${skuId}`, {
      credentials: 'include'
    })
  };
}

// 订单详情查询
export function fetchOrderDetail(orderId, userId) {
  return {
    types: [ORDER_FETCH_REQUEST, ORDER_DETAIL_SUCCESS, ORDER_FETCH_RESET],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(`${BAO_XIAN_ORDER_DETAIL}?orderId=${orderId}&userId=${userId}`, {
      credentials: 'include'
    }),
    orderId
  };
}

// 订单支付请求
export function fetchOrderPayment(orderId) {
  return {
    types: [ORDER_FETCH_REQUEST, (dispatch, getState, json) => {
      if (json.data.flag == 2) {
        // 富文本方式
        if (json.errno == 0) {
          // 支付页面
          window.localStorage.setItem('payHtml', json.data.payHtml);
          dispatch(push(`/pay`))
        } else {
          // 抛错
          return sendMessage('支付异常，请重新购买')
        }
      } else if (json.data.flag == 3) {
        standardPost(json.data.payUrl, json.data.paramJson, 'POST')
      } else if(json.data.flag == 4){
        standardPost(json.data.payUrl, json.data.paramJson, 'GET')
      } else {
        paySubmit(json.data); //拉起支付
      }
    }, ORDER_FETCH_RESET],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(`${BAO_XIAN_ORDER_PAY}?orderId=${orderId}&payType=${getPayType()}&clientType=${getPlatform()}`, {
      credentials: 'include'
    })
  };
}

// 订单取消请求
export function fetchOrderCancel(orderId) {
  return {
    types: [ORDER_FETCH_REQUEST, (dispatch, getState, json) => {
      dispatch({
        type: ORDER_CANCEL_SUCCESS,
        orderId,
      });
      dispatch(sendMessage('订单取消成功！', 101));
    }, ORDER_FETCH_RESET],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(`${BAO_XIAN_ORDER_CANCEL}?orderId=${orderId}`, {
      credentials: 'include'
    }),
    orderId
  };
}

// 订单退保申请
export function fetchOrderRefund(orderId, refundMsg) {
  return {
    types: [ORDER_FETCH_REQUEST, (dispatch, getState, json) => {
      dispatch(sendMessage('退保成功，保费将于5个工作日内返回到您的账户!', 201));
      dispatch({ type: ORDER_FETCH_RESET });
    }, ORDER_FETCH_RESET],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(`${BAO_XIAN_ORDER_REFUND}?orderId=${orderId}&refund_msg=${refundMsg}`, {
      credentials: 'include'
    }),
    orderId
  };
}

// 提交订单评价
export function submitOrderComment(opt) {
  if (!opt.level) {
    return (dispatch) => {
      dispatch(sendMessage('请选择评价星级~'));
    }
  }
  if (!opt.content) {
    return (dispatch) => {
      dispatch(sendMessage('请填写您的评价意见~'));
    }
  } else if (opt.content.length < 10) {
    return (dispatch) => {
      dispatch(sendMessage('请至少评价10个字~'));
    }
  } else if (opt.content.length > 100) {
    return (dispatch) => {
      dispatch(sendMessage('请评价字数少于100~'));
    }
  }
  opt.source = 2;
  opt.level = opt.level || '5'; // 评价星级

  return {
    types: [ORDER_FETCH_REQUEST, (dispatch, getState, json) => {
      dispatch(push('/order/list'));
    }, ORDER_FETCH_RESET],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(`${BAO_XIAN_ORDER_COMMENT}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      },
      credentials: 'include',
      body: param(opt)
    })
  };
}

//获取产品推荐数据
export function fetchProductsOfOrderSuccess() {
  return {
    types: [ORDER_FETCH_REQUEST, ORDER_SUCCESS_PRODUCT, ORDER_FETCH_RESET],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(CMS_ORDER_SUCCESS_PRODUCT),
    isSuccess: () => true
  };
};

// 图片上传开始
export function beginUpload() {
  return {
    type: ORDER_FETCH_REQUEST
  };
};

// 图片上传成功
export function endUpload() {
  return {
    type: CLAIM_OTAIN_SUCCESS
  };
};

// 图片上传失败
export function upLoadError() {
  return {
    type: CLAIM_OTAIN_FAIL
  };
};

// 提交信息
export function submitClaimInfo (info) {
  return {
    types: [CLAIM_OTAIN_REQUEST, (dispatch, getState, json) => {
      dispatch({
        type: CLAIM_OTAIN_SUCCESS
      })
      dispatch(push(`/claim/obtain/success`))
    }, CLAIM_OTAIN_FAIL],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(`${CLAIM_OBTAIN_SUBMIT}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      },
      credentials: 'include',
      body: param({data: JSON.stringify(info)})
    })
  }
}

// 删除信息
export function deleteApplyClaim () {
  return {
    type: DELETE_APPLY_CLAIM
  }
}

// 理赔状态查询详情
export function fetchClaimState (phone, type) {
  return {
    types: [REQUEST_CLAIM_DETAIL, (dispatch, getState, json) => {
      if (json.errno == 0) {
        dispatch({
          type: CLAIM_DETAIL_SUCCESS,
          data: json.data
        })
        if (json.data && !type) {
          // 由type不存在，表明是查询理赔结果，存在表示是重新提交
          dispatch(push(`/check/claim/${json.data.claimStatus}?phone=${phone}`))
        }
      }
    }, CLAIM_DETAIL_FAILURE],
    callAPI: () => fetch(`${API_FETCH_CLAIM_DETAIL}?phone=${phone}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      },
      credentials: 'include'
    })
  }
}

// 车险订单试算提交接口
export function submitCarInsurInfo (info) {
  return {
    types: [SUBMIT_CAR_INSURANCE, (dispatch, getState, json) => {
      dispatch({
        type: CAR_INSURANCE_ORDER_SUCCESS
      })
      dispatch(push(`/car/insurance/status`))
    }, CAR_INSURANCE_ORDER_FAILURE],
    shouldCallAPI: getState => !getState().getIn(['order', 'isFetching']),
    callAPI: () => fetch(`${CAR_INSURANCE_ORDER}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      },
      credentials: 'include',
      body: param({data: JSON.stringify(info)})
    })
  }
}

// 车险订单列表
export function fetchCarInsurOrderList () {
  return {
    types: [SUBMIT_CAR_INSURANCE_ORDER_LIST, CAR_INSURANCE_ORDER_LIST_SUCCESS, CAR_INSURANCE_ORDER_LIST_FAILURE],
    // shouldCallAPI: getState => !getState().getIn(['', 'isFetching']),
    callAPI: () => fetch(`${CAR_INSURANCE_ORDER_LIST}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      },
      credentials: 'include'
    })
  }
}

// 下单成功后获取购买资格
export function fetchBindingQualify (itemId, successCallback) {
  return {
    types: [CHECK_BINDING_QUALIFY, CHECK_BINDING_QUALIFY_SUCCESS, CHECK_BINDING_QUALIFY_FAILURE],
    callAPI: () => fetch(`${INSURANCE_BINDING_QUALIFY}?itemId=${itemId}`),
    successCallback
  }
}

// 申请退保
export function fetchApplyClaimCMS() {
  return {
    types: [APPLY_CLAIM_REQUEST, APPLY_CLAIM_CMS_SUCCESS, APPLY_CLAIM_FAIL],
    callAPI: () => fetch(APPLY_CLAIM_CONFIG),
    isSuccess: json => json.code == 0
  }
}

export function submitApplyClaim(data, successCallback) {
  return {
    types:[APPLY_CLAIM_REQUEST, APPLY_CLAIM_SUCCESS, (dispatch, getState, json) => {
      dispatch({type: APPLY_CLAIM_FAIL})
      dispatch(sendMessage(json.errmsg))
      if (window.localjs) {
        window.localjs.disableRefresh()
      }
    }],
    callAPI: () => fetch(`${APPLY_CLAIM_SUBMIT}?data=${encodeURIComponent(JSON.stringify(data))}`, {
      method: 'GET',
      credentials: 'include',
    }),
    successCallback
  }
}

// 泰康微信代扣获取url
export function fetchSignurl(orderId, successCallback) {
  return {
    types: [types.FETCH_SIGNURL, types.FETCH_SIGNURL_SUCCESS, types.FETCH_SIGNURL_FAILURE],
    callAPI: () => fetch(`${TAIKANG_ONLINE_API.SIGNURL}?orderId=${orderId}`),
    successCallback
  }
}
// 泰康代扣结果查询
export function fetchCallbackStatus(orderId, successCallback) {
  return {
    types: [types.FETCH_STATUS, types.FETCH_STATUS_SUCCESS, types.FETCH_STATUS_FAILURE],
    callAPI: () => fetch(`${TAIKANG_ONLINE_API.CALLBACK_STATUS}?orderId=${orderId}`),
    successCallback
  }
}

// export async function fetchPolling(orderId) {
//   let res = await fetch(`${TAIKANG_ONLINE_API.CALLBACK_STATUS}?orderId=${orderId}`)
//   let json = await res.json()
//   if (json.data.status == 1) {
//     window.location.href = json.data.resultUrl
//   }
// }

// setInterval(()=>fetchPolling(orderId), 1000)

// 确认出单
export function fetchConfirmSign(orderId, successCallback) {
  return {
    types: [types.FETCH_CONFIRM, types.FETCH_CONFIRM_SUCCESS, types.FETCH_CONFIRM_FAILURE],
    callAPI: () => fetch(`${TAIKANG_ONLINE_API.CONFIRM_SIGN}?orderId=${orderId}`),
    successCallback
  }
}