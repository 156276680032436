import {
  Map
} from 'immutable'
import {
  CHECK_FRIEGHT_INSURANCE_STATUS, 
  CHECK_FRIEGHT_INSURANCE_SUCCESS, 
  CHECK_FRIEGHT_INSURANCE_FAILURE,
  CHECK_FRIEGHT_CMS,
  CHECK_FRIEGHT_CMS_SUCCESS,
  CHECK_FRIEGHT_CMS_FAILURE
} from '../constants/actionTypes'

const initialState = Map({
  fetching: false,
  orderData: {},
  frieghtCMS: {},
})

export default function frieghtInsurance(state = initialState, action) {
  switch (action.type) { 
    case CHECK_FRIEGHT_INSURANCE_STATUS:
    case CHECK_FRIEGHT_CMS:
      return state.set('fetching', true);
    case CHECK_FRIEGHT_INSURANCE_SUCCESS:
      return state.set('orderData', action.data).set('fetching', false);
    case CHECK_FRIEGHT_CMS_SUCCESS:
      return state.set('frieghtCMS', action.data).set('fetching', false);
    case CHECK_FRIEGHT_INSURANCE_FAILURE:
    case CHECK_FRIEGHT_CMS_FAILURE:
      return state.set('fetching', false);
    default: 
      return state;
  }
}
