import {
  Map
} from 'immutable'
import {
  DELIVERY_SAFEGUARD_ACTIONS as Actions,
  RESTRICT_SUCCESS
} from '../constants/actionTypes'

const initialState = Map({
  fetching: false,
  orderInfo: {},
  cmsConfig: {},
  applyInitInfo: {
    claimTypeList: []
  },
  applyAmount: 0
})

export default function deliverySafeguard(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_ORDER_INFO:
    case Actions.FETCH_CMS_CONFIG:
    case Actions.FETCH_APPLY_INIT:
    case Actions.CACU_APPLY_AMOUT:
    case Actions.SUBMIT:
      return state.set('fetching', true)
    case Actions.FETCH_ORDER_INFO_FAILURE:
    case Actions.FETCH_CMS_CONFIG_FAILURE:
    case Actions.FETCH_APPLY_INIT_FAILURE:
    case Actions.CACU_APPLY_AMOUT_FAILURE:
    case Actions.SUBMIT_FAILURE:
      return state.set('fetching', false)
    case Actions.FETCH_ORDER_INFO_SUCCESS:
      return state.set('orderInfo', { ...action.data,
        statusHistoryList: action.data.statusHistoryList ? action.data.statusHistoryList.reverse() : []
      }).set('fetching', false)
    case Actions.FETCH_CMS_CONFIG_SUCCESS:
      return state.set('cmsConfig', action.data).set('fetching', false)
    case Actions.FETCH_APPLY_INIT_SUCCESS:
      return state.set('applyInitInfo', action.data).set('fetching', false)
    case Actions.CACU_APPLY_AMOUT_SUCCESS:
      return state.set('applyAmount', action.data.claimAmout).set('fetching', false)
    case Actions.CACU_APPLY_AMOUT_SUCCESS:
      return state.set('fetching', false)
    default:
      return state
  }
}