// 消息处理
export const MESSAGE_SEND = 'MESSAGE_SEND'; //消息提示
export const MESSAGE_CONFIRMATION = 'MESSAGE_CONFIRMATION'; //消息确认
export const MESSAGE_RESET = 'MESSAGE_RESET'; //消息重置

//首页数据初始化
//cms main
export const MAIN_DATA_REQUEST = 'MAIN_DATA_REQUEST';
export const MAIN_DATA_SUCCESS = 'MAIN_DATA_SUCCESS';
export const MAIN_DATA_FAILURE = 'MAIN_DATA_FAILURE';
//cms channel
export const CHANNEL_DATA_REQUEST = 'CHANNEL_DATA_REQUEST';
export const CHANNEL_DATA_SUCCESS = 'CHANNEL_DATA_SUCCESS';
export const CHANNEL_DATA_FAILURE = 'CHANNEL_DATA_FAILURE';

//商品数据请求
export const PRODUCT_DATA_REQUEST = 'PRODUCT_DATA_REQUEST';
export const PRODUCT_FETCH_REQUEST = 'PRODUCT_FETCH_REQUEST';
export const PRODUCT_FETCH_RESET = 'PRODUCT_FETCH_RESET';
//保险商品列表
export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAILURE = 'PRODUCT_LIST_FAILURE';
//保险商品祥情
export const PRODUCT_DETAIL_REQUEST = 'PRODUCT_DETAIL_REQUEST';
export const PRODUCT_DETAIL_SUCCESS = 'PRODUCT_DETAIL_SUCCESS';
export const PRODUCT_DETAIL_FAILURE = 'PRODUCT_DETAIL_FAILURE';
//保险分享佣金key获取接口
export const INSURANCE_SHAREKEY_REQUEST = 'INSURANCE_SHAREKEY_REQUEST';
export const INSURANCE_SHAREKEY_SUCCESS = 'INSURANCE_SHAREKEY_SUCCESS';
export const INSURANCE_SHAREKEY_FAILURE = 'INSURANCE_SHAREKEY_FAILURE';
//保险商品活动接口
export const ACTIVITY_REQUEST = 'ACTIVITY_REQUEST';
export const ACTIVITY_SUCCESS = 'ACTIVITY_SUCCESS';
export const ACTIVITY_FAILURE = 'ACTIVITY_FAILURE';
//保险商品活动资格id查询
export const ACTIVITY_QUALIFY_REQUEST = 'ACTIVITY_QUALIFY_REQUEST';
export const ACTIVITY_QUALIFY_SUCCESS = 'ACTIVITY_QUALIFY_SUCCESS';
export const ACTIVITY_QUALIFY_FAILURE = 'ACTIVITY_QUALIFY_FAILURE';
export const PRODUCT_ACTIVITY_FAILURE_DETAIL_SUCCESS = 'PRODUCT_ACTIVITY_FAILURE_DETAIL_SUCCESS';
//保险主商品是否下架隐藏
export const PRODUCT_STATUS_REQUEST = 'PRODUCT_STATUS_REQUEST';
export const PRODUCT_STATUS_SUCCESS = 'PRODUCT_STATUS_SUCCESS';
export const PRODUCT_STATUS_FAILURE = 'PRODUCT_STATUS_FAILURE';
//保险条款
export const CLAUSE_LIST_REQUEST = 'CLAUSE_LIST_REQUEST';
export const CLAUSE_LIST_SUCCESS = 'CLAUSE_LIST_SUCCESS';
export const CLAUSE_LIST_FAILURE = 'CLAUSE_LIST_FAILURE';
//健康告知与职业工种告知信息
export const PRODUCT_NOTICE_SUCCESS = 'PRODUCT_NOTICE_SUCCESS';
//职业工种类型
export const INSURANT_JOBNO_SUCCESS = 'INSURANT_JOBNO_SUCCESS';
//长期保地区类型选择
export const INSURANT_AREAINFO_SUCCESS = 'INSURANT_AREAINFO_SUCCESS';
//投保信息
export const INSURANCE_INFO_REQUEST = 'INSURANCE_INFO_REQUEST';
export const INSURANCE_INFO_SUCCESS = 'INSURANCE_INFO_SUCCESS';
export const INSURANCE_INFO_FAILURE = 'INSURANCE_INFO_FAILURE';
//常用被保人信息
export const RECOGNIZEE_LIST_REQUEST = 'RECOGNIZEE_LIST_REQUEST';
export const RECOGNIZEE_LIST_SUCCESS = 'RECOGNIZEE_LIST_SUCCESS';
export const RECOGNIZEE_LIST_FAILURE = 'RECOGNIZEE_LIST_FAILURE';
//新增被保人信息
export const RECOGNIZEE_UPDATE_REQUEST = 'RECOGNIZEE_UPDATE_REQUEST';
export const RECOGNIZEE_UPDATE_SUCCESS = 'RECOGNIZEE_UPDATE_SUCCESS';
export const RECOGNIZEE_UPDATE_FAILURE = 'RECOGNIZEE_UPDATE_FAILURE';
//保险金试算
export const CURRENT_CITY_DATA_LOADED = 'CURRENT_CITY_DATA_LOADED';
export const CITY_ADVISER_DATA_LOADED = 'CITY_ADVISER_DATA_LOADED';
// 保险顾问预约
export const MODYFY_CURRENT_CITY = 'MODYFY_CURRENT_CITY';
// 数据请求
export const APPOINT_FETCH_REQUEST = 'APPOINT_FETCH_REQUEST';
export const APPOINT_RESET_FETCH = 'APPOINT_RESET_FETCH';

//个人中心订单相关
export const ORDER_FETCH_REQUEST = 'ORDER_FETCH_REQUEST';
export const ORDER_FETCH_RESET = 'ORDER_FETCH_RESET';

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';

//订单创建
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAILURE = 'ORDER_CREATE_FAILURE';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAILURE = 'ORDER_LIST_FAILURE';
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS';
export const ORDER_DETAIL_FAILURE = 'ORDER_DETAIL_FAILURE';
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS';
export const ORDER_SUCCESS_PRODUCT = 'ORDER_SUCCESS_PRODUCT';
//评论
export const COMMENT_QUERY_REQUEST = 'COMMENT_QUERY_REQUEST';
export const COMMENT_QUERY_SUCCESS = 'COMMENT_QUERY_SUCCESS';
export const COMMENT_QUERY_FAILURE = 'COMMENT_QUERY_FAILURE';

export const QUERY_RENEW_SUCCESS = 'QUERY_RENEW_SUCCESS';

// 小雨伞试算
export const UMBRELLA_RESTRICT_REQUEST = 'UMBRELLA_RESTRICT_REQUEST';
export const UMBRELLA_RESTRICT_SUCCESS = 'UMBRELLA_RESTRICT_SUCCESS';
export const UMBRELLA_RESTRICT_FAILURE = 'UMBRELLA_RESTRICT_FAILURE';

// 理赔资料上传
export const CLAIM_OTAIN_REQUEST = 'CLAIM_OTAIN_REQUEST';
export const CLAIM_OTAIN_SUCCESS = 'CLAIM_OTAIN_SUCCESS';
export const CLAIM_OTAIN_FAIL = 'CLAIM_OTAIN_FAIL';

// 理赔提交接口
export const CLAIM_OTAIN_PIC_REQUEST = 'CLAIM_OTAIN_PIC_REQUEST';
export const CLAIM_OTAIN_PIC_SUCCESS = 'CLAIM_OTAIN_PIC_SUCCESS';
export const CLAIM_OTAIN_PIC_FAIL = 'CLAIM_OTAIN_PIC_FAIL';

// 登录态验证
export const FETCH_LOGIN = 'FETCH_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
// 理赔详情接口查询
export const REQUEST_CLAIM_DETAIL = 'REQUEST_CLAIM_DETAIL';
export const CLAIM_DETAIL_SUCCESS = 'CLAIM_DETAIL_SUCCESS';
export const CLAIM_DETAIL_FAILURE = 'CLAIM_DETAIL_FAILURE';
//
export const DELETE_APPLY_CLAIM = 'DELETE_APPLY_CLAIM';
// 小雨伞长期险被保人职业工作和省市区
export const XIAOYUSAN_INFO_REQUEST = 'XIAOYUSAN_INFO_REQUEST';
export const XIAOYUSAN_INFO_SUCCESS = 'XIAOYUSAN_INFO_SUCCESS';
export const XIAOYUSAN_INFO_FAILURE = 'XIAOYUSAN_INFO_FAILURE';
// 车险保险公司信息
export const FETCH_CAR_INSURANCE = 'FETCH_CAR_INSURANCE';
export const CAR_INSURANCE_COMP_INFO_SUCCESS = 'CAR_INSURANCE_SUCCESS';
export const CAR_INSURANCE_COMP_INFO_FAILURE = 'CAR_INSURANCE_FAILURE';
// 车险订单接口
export const SUBMIT_CAR_INSURANCE = 'SUBMIT_CAR_INSURANCE';
export const CAR_INSURANCE_ORDER_SUCCESS = 'CAR_INSURANCE_ORDER_SUCCESS';
export const CAR_INSURANCE_ORDER_FAILURE = 'CAR_INSURANCE_ORDER_FAILURE';
// 车险订单列表接口
export const SUBMIT_CAR_INSURANCE_ORDER_LIST = 'SUBMIT_CAR_INSURANCE_ORDER_LIST';
export const CAR_INSURANCE_ORDER_LIST_SUCCESS = 'CAR_INSURANCE_ORDER_LIST_SUCCESS';
export const CAR_INSURANCE_ORDER_LIST_FAILURE = 'CAR_INSURANCE_ORDER_LIST_FAILURE';
// 车牌号核查
export const CHECK_PLATE_NUMBER = 'CHECK_PLATE_NUMBER';
export const CHECK_PLATE_NUMBER_SUCCESS = 'CHECK_PLATE_NUMBER_SUCCESS';
export const CHECK_PLATE_NUMBER_FAILURE = 'CHECK_PLATE_NUMBER_FAILURE';
// 投保、续保页面职业工种与省市区
export const INSURE_AND_RECOGNIZEE_INDUSTRY_SUCCESS = 'INSURE_AND_RECOGNIZEE_INDUSTRY_SUCCESS';
export const INSURE_AND_RECOGNIZEE_JOBS_SUCCESS = 'INSURE_AND_RECOGNIZEE_JOBS_SUCCESS'

// 收货保障
export const DELIVERY_SAFEGUARD_ACTIONS = {
  FETCH_ORDER_INFO: 'deliverySafeguard/FETCH_ORDER_INFO',
  FETCH_ORDER_INFO_SUCCESS: 'deliverySafeguard/FETCH_ORDER_INFO_SUCCESS',
  FETCH_ORDER_INFO_FAILURE: 'deliverySafeguard/FETCH_ORDER_INFO_FAILURE',
  FETCH_CMS_CONFIG: 'deliverySafeguard/CMS_CONFIG',
  FETCH_CMS_CONFIG_SUCCESS: 'deliverySafeguard/FETCH_CMS_CONFIG_SUCCESS',
  FETCH_CMS_CONFIG_FAILURE: 'deliverySafeguard/FETCH_CMS_CONFIG_FAILURE',
  FETCH_APPLY_INIT: 'deliverySafeguard/FETCH_APPLY_INIT',
  FETCH_APPLY_INIT_SUCCESS: 'deliverySafeguard/FETCH_APPLY_INIT_SUCCESS',
  FETCH_APPLY_INIT_FAILURE: 'deliverySafeguard/FETCH_APPLY_INIT_FAILURE',
  CACU_APPLY_AMOUT: 'deliverySafeguard/CACU_APPLY_AMOUT',
  CACU_APPLY_AMOUT_SUCCESS: 'deliverySafeguard/CACU_APPLY_AMOUT_SUCCESS',
  CACU_APPLY_AMOUT_FAILURE: 'deliverySafeguard/CACU_APPLY_AMOUT_FAILURE',
  SUBMIT: 'deliverySafeguard/SUBMIT',
  SUBMIT_SUCCESS: 'deliverySafeguard/SUBMIT_SUCCESS',
  SUBMIT_FAILURE: 'deliverySafeguard/SUBMIT_FAILURE',
}

// 捆绑销售购买资格
export const CHECK_BINDING_STATUS = 'CHECK_BINDING_STATUS';
export const CHECK_BINDING_STATUS_SUCCESS = 'CHECK_BINDING_STATUS_SUCCESS';
export const CHECK_BINDING_STATUS_FAILURE = 'CHECK_BINDING_STATUS_FAILURE';

// 下单成功后获取购买资格
export const CHECK_BINDING_QUALIFY = 'CHECK_BINDING_QUALIFY';
export const CHECK_BINDING_QUALIFY_SUCCESS = 'CHECK_BINDING_QUALIFY_SUCCESS';
export const CHECK_BINDING_QUALIFY_FAILURE = 'CHECK_BINDING_QUALIFY_FAILURE';

// 运费险
export const CHECK_FRIEGHT_INSURANCE_STATUS = 'CHECK_FRIEGHT_INSURANCE_STATUS';
export const CHECK_FRIEGHT_INSURANCE_SUCCESS = 'CHECK_FRIEGHT_INSURANCE_SUCCESS';
export const CHECK_FRIEGHT_INSURANCE_FAILURE = 'CHECK_FRIEGHT_INSURANCE_FAILURE';

export const CHECK_FRIEGHT_CMS = 'CHECK_FRIEGHT_CMS'
export const CHECK_FRIEGHT_CMS_SUCCESS = 'CHECK_FRIEGHT_CMS_SUCCESS'
export const CHECK_FRIEGHT_CMS_FAILURE = 'CHECK_FRIEGHT_CMS_FAILURE'

//获取银行列表
export const FETCH_BANKLIST_REQUEST = 'FETCH_BANKLIST_REQUEST'
export const FETCH_BANKLIST_SUCCESS = 'FETCH_BANKLIST_SUCCESS'
export const FETCH_BANKLIST_FAILURE = 'FETCH_BANKLIST_FAILURE'

// 申请赔付
export const APPLY_CLAIM_CMS_SUCCESS = 'APPLY_CLAIM_CMS_SUCCESS'
export const APPLY_CLAIM_REQUEST = 'APPLY_CLAIM_REQUEST'
export const APPLY_CLAIM_SUCCESS = 'APPLY_CLAIM_SUCCESS'
export const APPLY_CLAIM_FAIL = 'APPLY_CLAIM_FAIL'

//阳光保险重疾险
export const SUNSHINE_AREA_LIST_REQUEST = 'SUNSHINE_AREA_LIST_REQUEST'
export const SUNSHINE_AREA_LIST_SUCCESS = 'SUNSHINE_AREA_LIST_SUCCESS'
export const SUNSHINE_AREA_LIST_FAILURE = 'SUNSHINE_AREA_LIST_FAILURE'
export const SUNSHINE_ESTIMATE_PRICE_REQUEST = 'SUNSHINE_ESTIMATE_PRICE_REQUEST'
export const SUNSHINE_ESTIMATE_PRICE_SUCCESS = 'SUNSHINE_ESTIMATE_PRICE_SUCCESS'
export const SUNSHINE_ESTIMATE_PRICE_FAILURE = 'SUNSHINE_ESTIMATE_PRICE_FAILURE'
export const SUNSHINE_REDIRECT_REQUEST = 'SUNSHINE_REDIRECT_REQUEST'
export const SUNSHINE_REDIRECT_SUCCESS = 'SUNSHINE_REDIRECT_SUCCESS'
export const SUNSHINE_REDIRECT_FAILURE = 'SUNSHINE_REDIRECT_FAILURE'

// 保险券
export const MY_COUPON = {
  FETCH: 'myCoupon/FETCH',
  FETCH_DISPLAY_SUCCESS: 'myCoupon/FETCH_DISPLAY_SUCCESS',
  FETCH_DISPLAY_FAILURE: 'myCoupon/FETCH_DISPLAY_FAILURE',
  FETCH_AVAILABLE_SUCCESS: 'myCoupon/FETCH_AVAILABLE_SUCCESS',
  FETCH_AVAILABLE_FAILURE: 'myCoupon/FETCH_AVAILABLE_FAILURE',
  FETCH_COLLECT_SUCCESS: 'myCoupon/FETCH_COLLECT_SUCCESS',
  FETCH_COLLECT_FAILURE: 'myCoupon/FETCH_COLLECT_FAILURE',
  FETCH_MY_ALL_SUCCESS: 'myCoupon/FETCH_MY_ALL_SUCCESS',
  FETCH_MY_ALL_FAILURE: 'myCoupon/FETCH_MY_ALL_FAILURE',
  FETCH_ALL_COUPON_SUCCESS: 'myCoupon/FETCH_ALL_COUPON_SUCCESS',
  FETCH_ALL_COUPON_FAILURE: 'myCoupon/FETCH_ALL_COUPON_FAILURE',
  FETCH_DETAIL_SUCCESS: 'myCoupon/FETCH_DETAIL_SUCCESS',
  FETCH_DETAIL_FAILURE: 'myCoupon/FETCH_DETAIL_FAILURE',
  FETCH_RETURNED_SUCCESS: 'myCoupon/FETCH_RETURNED_SUCCESS',
  FETCH_RETURNED_FAILURE: 'myCoupon/FETCH_RETURNED_FAILURE',
}

// 泰康微信代扣
export const TAIKANG_ONLINE = {
  FETCH_SIGNURL: 'taikang/FETCH_SIGNURL',
  FETCH_SIGNURL_SUCCESS: 'taikang/FETCH_SIGNURL_SUCCESS',
  FETCH_SIGNURL_FAILURE: 'taikang/FETCH_SIGNURL_FAILURE',
  FETCH_STATUS: 'taikang/FETCH_STATUS',
  FETCH_STATUS_SUCCESS: 'taikang/FETCH_STATUS_SUCCESS',
  FETCH_STATUS_FAILURE: 'taikang/FETCH_STATUS_FAILURE',
  FETCH_CONFIRM: 'taikang/FETCH_CONFIRM',
  FETCH_CONFIRM_SUCCESS: 'taikang/FETCH_CONFIRM_SUCCESS',
  FETCH_CONFIRM_FAILURE: 'taikang/FETCH_CONFIRM_FAILURE',
}

export const MAINTAIN_STATUS_REQUEST = 'MAINTAIN_STATUS_REQUEST'
export const MAINTAIN_STATUS_SUCCESS = 'MAINTAIN_STATUS_SUCCESS'
export const MAINTAIN_STATUS_FAILURE = 'MAINTAIN_STATUS_FAILURE'

export const SWITCH_ITEMS_REQUEST = 'SWITCH_ITEMS_REQUEST'
export const SWITCH_ITEMS_SUCCESS = 'SWITCH_ITEMS_SUCCESS'
export const SWITCH_ITEMS_FAILURE = 'SWITCH_ITEMS_FAILURE'


export const PCINFO_FETCH_REQUEST='PCINFO_FETCH_REQUEST'
export const PCINFO_FETCH_SUCCESS = 'PCINFO_FETCH_SUCCESS';
export const PCINFO_FETCH_FAILURE = 'PCINFO_FETCH_FAILURE';

export const RESTRICT_REQUEST = 'RESTRICT_REQUEST'
export const SUBMIT_INSURANCE_ORDER = 'SUBMIT_INSURANCE_ORDER'
export const RESTRICT_FAILURE = 'RESTRICT_FAILURE'