import App from "./containers/App";
import { appointResetFetch } from "./actions/appoint";
import { resetOrderFetch } from "./actions/order";

if (typeof require.ensure !== "function") require.ensure = (d, c) => c(require);

function routes(store) {
  // 保险首页的推荐产品页面
  const productList = {
    path: "/product/list",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/ProductList").default)
      );
    }
  };
  // 保险频道主页
  const main = {
    path: "/main",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Main").default)
      );
    }
  };
  // 保险子频道
  const channel = {
    path: "/channel/:id",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Channel").default)
      );
    }
  };

  // 商品详情
  const detail = {
    path: "detail",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Product/Detail").default)
      );
    }
  };

  // 商品条款列表
  const clauseList = {
    path: "clause/list",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Product/ClauseList").default)
      );
    }
  };

  // 商品条款详情
  const clauseDetail = {
    path: "clause/detail/:index",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Product/ClauseDetail").default)
      );
    }
  };

  // 评论
  const comment = {
    path: "comment/:b2cid",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Product/CommentList").default)
      );
    }
  };

  // 商祥 container
  const product = {
    path: "product/:itemId",
    getIndexRoute(location, callback) {
      require.ensure([], require =>
        callback(null, {
          component: require("./containers/Product/Detail").default
        })
      );
    },
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Product").default)
      );
    },
    childRoutes: [detail, clauseList, clauseDetail, comment]
  };

  // 小雨伞安邦和谐健康
  const umbrellaRestrict = {
    path: "/umbrella/restrict/:itemId/:skuId",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/UmbrellaRestrict").default)
      );
    }
  };

  // 健康告知与职业工种告知信息
  const productNotice = {
    path: "/product/notice/:itemId/:skuId",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/ProductNotice").default)
      );
    }
  };

  // 投保表单
  const orderForm = {
    path: "/order/form/:itemId/:skuId",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/OrderForm").default)
      );
    }
  };

  // 续保表单
  const orderRenew = {
    path: "/order/renew/:itemId/:skuId/:orderId",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/OrderRenew").default)
      );
    }
  };

  // 续保表单
  const pay = {
    path: "/pay",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Pay").default)
      );
    }
  };

  // 城市选择
  const city = {
    path: "/city",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/City").default)
      );
    },
    onEnter() {
      store.dispatch(appointResetFetch());
    }
  };

  // 选择身边的保险顾问
  const adviser = {
    path: "/adviser",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Adviser").default)
      );
    },
    onEnter() {
      store.dispatch(appointResetFetch());
    }
  };

  // 选择我的专属顾问
  const adviserChoose = {
    path: "/adviser/choose",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/AdviserChoose").default)
      );
    }
  };

  // 预约咨询
  const appointment = {
    path: "/appointment",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Appointment").default)
      );
    }
  };

  // 增加被保人
  const recognizeeUpdate = {
    path: "/recognizee/update/:itemId/:skuId(/:recognizeeId)",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/RecognizeeUpdate").default)
      );
    }
  };

  // 预约成功
  const appointmentSuccess = {
    path: "/appointment/success",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/AppointmentSuccess").default)
      );
    }
  };

  // 保单列表，我的保单
  const orderList = {
    path: "/order/list",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/OrderList").default)
      );
    },
    onEnter() {
      store.dispatch(resetOrderFetch());
    }
  };

  // 保单详情
  const orderDetail = {
    path: "/order/detail/:orderId",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/OrderDetail").default)
      );
    },
    onEnter() {
      store.dispatch(resetOrderFetch());
    }
  };

  // 我要理赔
  const orderClaim = {
    path: "/order/claim",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/OrderClaim").default)
      );
    }
  };

  // 车险入口页面
  const carInsuranceEntry = {
    path: "/car/insurance/entry",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/CarInsuranceEntry").default)
      );
    }
  };

  // 车险投保页面
  const carInsurance = {
    path: "/car/insurance/type/:type",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/CarInsurance").default)
      );
    }
  };

  // 车险投保结果
  const carInsuranceStatus = {
    path: "/car/insurance/status",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/CarInsuranceStatus").default)
      );
    }
  };

  // 车险投保结果
  const carInsuranceOrderList = {
    path: "/car/insurance/order/list",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/CarInsureOrderList").default)
      );
    }
  };

  // 保险活动页理赔页面
  const claimObtain = {
    path: "/claim/obtain",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/ClaimObtain").default)
      );
    }
  };

  // 保险活动页理赔成功页面
  const claimObtainSuccess = {
    path: "/claim/obtain/success",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/ClaimObtainSuccess").default)
      );
    }
  };

  // 保险活动页理赔，输入电话查看页面
  const checkClaim = {
    path: "/check/claim",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/CheckClaim").default)
      );
    }
  };

  // 保险活动页理赔，输入电话查看页面
  const checkClaimState = {
    path: "/check/claim/:state",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/CheckClaimState").default)
      );
    }
  };

  // 用户评价
  const orderComment = {
    path: "/order/comment/:orderId",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/OrderComment").default)
      );
    },
    onEnter() {
      store.dispatch(resetOrderFetch());
    }
  };

  // 订单成功
  const orderSuccess = {
    path: "/order/success(/:orderId)",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/OrderSuccess").default)
      );
    }
  };

  // 订单成功
  const orderFailure = {
    path: "/order/failure",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/OrderFailure").default)
      );
    }
  };

  // 用户评价
  const policyCheck = {
    path: "/order/policy/check",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/PolicyCheck").default)
      );
    }
  };

  // 运费险
  const frieghtInsurance = {
    path: "/frieght-insurance/:id",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/FrieghtInsurance").default)
      );
    }
  };

  // 收货保障险
  const deliverySafeguard = {
    path: "/delivery-safeguard/:id",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/DeliverySafeguard").default)
      );
    }
  };
  // 运费险理赔
  const deliverySafeguardApply = {
    path: "/delivery-safeguard/:id/apply",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/DeliverySafeguardApply").default)
      );
    }
  };

  const deliverySafeguardApplyResult = {
    path: "/delivery-safeguard/result/:status",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(
          null,
          require("./containers/DeliverySafeguardApplyResult").default
        )
      );
    }
  };

  const insureRealize = {
    path: "check/insureRealize",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/InsureRealize").default)
      );
    }
  };

  // 申请理赔
  const applyClaim = {
    path: "apply-claim",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/ApplyClaim").default)
      );
    }
  };

  // 提交赔付申请后跳转
  const applyClaimResult = {
    path: "/apply-claim/result/:status",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/ApplyClaimResult").default)
      );
    }
  };

  // 赔付说明
  const applyClaimNotification = {
    path: "/apply-claim/notification",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/ApplyClaimNotification").default)
      );
    }
  };

  // 阳光保险
  const sunshineInsurance = {
    path: "/sunshine/insurance/:itemId/:skuId",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/SunshineInsurance").default)
      );
    }
  };

  //我的券
  const myCoupon = {
    path: "my/coupon",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/MyCoupon").default)
      );
    }
  };
  //历史券
  const myHistoryCoupon = {
    path: "my/historyCoupon",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/MyCoupon").default)
      );
    }
  };

  // 优惠券详情
  const couponDetail = {
    path: "coupon/detail",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/CouponDetail").default)
      );
    }
  };

  // 微信代扣协议提示
  const withholding = {
    path: "taikang_online/withholding",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Withholding").default)
      );
    }
  };

  // 中转页面
  const transfer = {
    path: "transfer",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/Transfer").default)
      );
    }
  };

  // 孩子王电子保险平台服务协议
  // const agreement = {
  //   path: '*',
  //   getComponent(location, callback) {
  //     require.ensure([], require => callback(null, require('./containers/Agreement').default));
  //   }
  // }

  // 保险pc官网-首页
  const home = {
    path: "/home",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/PcHomepage").default)
      );
    }
  };

  // 保险pc官网-信息披露
  const information = {
    path: "/we/information",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/PcInformation").default)
      );
    }
  };

  // 保险pc官网-关于我们
  const aboutUs = {
    path: "/we/about",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/PcAboutUs").default)
      );
    }
  };

  // 保险pc官网-关于我们
  const insuranceRule = {
    path: "/we/rule",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/PcInsuranceRule").default)
      );
    }
  };


  // 保险pc官网-关于我们
  const compamyNotice = {
    path: "/we/notice",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/PcCompanyNotice").default)
      );
    }
  };

  // 保险pc官网-联系我们
  const contactUs = {
    path: "/we/contact",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/PcContactUs").default)
      );
    }
  };

  // 保险pc官网-保险定制服务
  const service = {
    path: "/service",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/PcService").default)
      );
    }
  };

  // 保险pc官网-全国招募合伙人
  const recruit = {
    path: "/recruit",
    getComponent(location, callback) {
      require.ensure([], require =>
        callback(null, require("./containers/PcRecruit").default)
      );
    }
  };

  // 404
  const notFoundPage = {
    path: '*',
    getComponent(location, callback) {
      // require.ensure([], require => callback(null, require('./containers/NotFoundPage').default));
      require.ensure([], require => callback(null, require('./containers/PcHomepage').default));
    }
  }

  // 路由根目录
  const rootRoute = {
    path: "/",
    component: App,
    getIndexRoute(location, callback) {
      require.ensure([], require =>
        callback(null, { component: require("./containers/PcHomepage").default })
      );
    },
    childRoutes: [
      insureRealize,
      frieghtInsurance,
      deliverySafeguard,
      deliverySafeguardApply,
      deliverySafeguardApplyResult,
      productList,
      main,
      channel,
      product,
      umbrellaRestrict,
      productNotice,
      orderForm,
      city,
      adviser,
      adviserChoose,
      appointment,
      appointmentSuccess,
      orderList,
      orderDetail,
      orderClaim,
      carInsuranceEntry,
      carInsurance,
      carInsuranceStatus,
      carInsuranceOrderList,
      claimObtain,
      claimObtainSuccess,
      checkClaim,
      checkClaimState,
      orderComment,
      orderRenew,
      pay,
      orderSuccess,
      orderFailure,
      policyCheck,
      recognizeeUpdate,
      applyClaim,
      applyClaimResult,
      applyClaimNotification,
      sunshineInsurance,
      myCoupon,
      myHistoryCoupon,
      couponDetail,
      withholding,
      transfer,
      // agreement
      home,
      information,
      aboutUs,
      contactUs,
      insuranceRule,
      compamyNotice,
      service,
      recruit,
      // notFoundPage必须写在最下面，当路由匹配不到的时候跳回首页
      notFoundPage
    ]
  };

  return rootRoute;
}

export default routes;
