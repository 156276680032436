import isomorphicFetch from 'isomorphic-fetch';

//为Promise增加timeout
// function timeoutPromise(ms, promise) {
//   return new Promise((resolve, reject) => {
//     const timeoutId = setTimeout(() => {
//       reject(new Error('Fetch time'));
//     }, ms);
//     promise.then(
//       (res) => {
//         clearTimeout(timeoutId);
//         resolve(res);
//       },
//       (err) => {
//         clearTimeout(timeoutId);
//         reject(err);
//       }
//     );
//   });
// }

/**
 * 封装fetch方法，增加timeout超时，默认为5000ms
 *
 * 自定义方式
 * fetch('/getUserInfo', {
 *   timeout: 2000
 * })
 */
export default function fetch(url, {timeout = 60000, ...options} = {}) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error(`Fetch timeout[${timeout}ms][${url}]`));
    }, timeout);
    isomorphicFetch(url, options).then(
      (res) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err) => {
        clearTimeout(timeoutId);
        reject(err);
      }
    );
  });
};
