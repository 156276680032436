import { Map } from 'immutable';
import {
  MESSAGE_SEND,
  MESSAGE_CONFIRMATION,
  MESSAGE_RESET
} from '../constants/actionTypes';

const initialState = Map({
  code: 0,
  content: ''
});
export default function channel(state = initialState, action) {

  switch (action.type) {
    case MESSAGE_SEND:
      const { code, content } = action;
      return Map({code, content});

    case MESSAGE_CONFIRMATION:
      return initialState;

    case MESSAGE_RESET:
      return initialState;

    default:
      return state;
  }
}
