import {
  MESSAGE_SEND,
  MESSAGE_CONFIRMATION,
  MESSAGE_RESET
} from '../constants/actionTypes';

// 发送消息
export function sendMessage(content = '接口异常,请稍后再试!', code = 9999) {
  return {
    type: MESSAGE_SEND,
    content,
    code
  };
}

// 确认消息
export function confirmMessage() {
  return {
    type: MESSAGE_CONFIRMATION
  }
}

// 消息状态重置
export function resetMessage() {
  return {
    type: MESSAGE_RESET
  }
}
