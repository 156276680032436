import { sendMessage } from '../actions/message';
import { redirectPassport } from '../common/js/redirect';

export default function api({ dispatch, getState }) {
  return next => async action => {
    const {
      types,        // 必需，[requestType, successType, failureType]，type: string or (dispatch, getState, [json]) => {}
      messages = [],    // [errorMessage(默认为errmsg), successMessage(默认为空)]
      shouldCallAPI = () => true,   // getState => {}
      callAPI,      // 必需，getState => fetch()
      isSuccess = () => false,    // 成功判断条件，json => {}, 为 false 则使用 errno === 0 判断（判断 cms api 时要使用）
      successCallback = () => {},  // success callback
      ...rest
    } = action;

    if (!(types && callAPI)) {
      return next(action);
    }

    if (!Array.isArray(types) || types.length !== 3 ||
      !types.every(type => (typeof type === 'string' || typeof type === 'function'))) {
      throw new Error('Expected types to be an array of three string or function.');
    }
    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (!shouldCallAPI(getState)) {
      return;
    }

    const [requestType, successType, failureType] = types;
    const [errorMessage, successMessage] = messages;
    
    if (typeof requestType === 'function') {
      requestType(dispatch, getState);
    } else {
      dispatch({ ...rest, type: requestType });
    }

    try {
      const response = await callAPI();
      const json = await response.json();
      if (isSuccess(json) || json.errno == 0) {
        if (typeof successType === 'function') {
          return successType(dispatch, getState, json);  //return 支持异步调用
        } else {
          successMessage && dispatch(sendMessage(successMessage));
          dispatch({ ...rest, type: successType, data: json.data });
          await successCallback(json)
        }
      } else if (json.errno == 1024) {    // 登录跳转，改由页面控制
        return redirectPassport();
      } else {
        if (typeof failureType === 'function') {
          failureType(dispatch, getState, json);
        } else {
          dispatch(sendMessage(errorMessage || json.errmsg, json.errno));
          dispatch({ ...rest, type: failureType });
        }
      }
    } catch (e) {
      console.log(e);
      // alert(e)
      // if (__CLIENT__) {
        window.Raven && window.Raven.captureException(e);
      // }
      if (e.indexOf('cancel') == -1) {
        dispatch({ ...rest, type: failureType });
        dispatch(sendMessage('接口出现异常, 请稍后再试!'));
      }
    }
  };
}
