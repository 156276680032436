import { Map } from 'immutable';
import {
  CURRENT_CITY_DATA_LOADED,
  CITY_ADVISER_DATA_LOADED,
  APPOINT_FETCH_REQUEST,
  MESSAGE_CONFIRMATION,
  APPOINT_RESET_FETCH,
  MODYFY_CURRENT_CITY
} from '../constants/actionTypes';
const initialState = Map({
  isFetching: false,
  locatedCity: null,
  currentCity: null
});

export default function appoint(state = initialState, action) {
  switch (action.type) {
    case APPOINT_FETCH_REQUEST:
      return state.merge({ isFetching: true });
    case CURRENT_CITY_DATA_LOADED:
      //当前定位城市
      return state.merge({ locatedCity: action.data });
    case CITY_ADVISER_DATA_LOADED:
      return state.merge(action.data);
    case MESSAGE_CONFIRMATION:
      return state.merge({ isFetching: false });
    case APPOINT_RESET_FETCH:
      return state.merge({ isFetching: false });
    case MODYFY_CURRENT_CITY:
      return state.merge({ currentCity: action.data });
    default:
      return state;
  }
}
