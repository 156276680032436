import {
	Map,
} from 'immutable'
import {
	MY_COUPON as types
} from '../constants/actionTypes'
import { Toast } from 'antd-mobile'

const initialState = Map({
	fetching: true,
	displayList: [],
	availableList: [],
	myCoupon: Map({ // 我的券
		page: 0,
		total: 0,
		list: []
	}),
	myBeforePayList: [], // 支付页面查看我的优惠券
	detail: {},
	returnedList: []
})

export default function deliverySafeguard(state = initialState, action) {
  switch (action.type) {
		case types.FETCH:
      return state.set('fetching', true)
		case types.FETCH_DISPLAY_FAILURE:
		case types.FETCH_AVAILABLE_FAILUR:
		case types.FETCH_COLLECT_FAILURE:
		case types.FETCH_MY_ALL_FAILURE:
		case types.FETCH_DETAIL_FAILURE:
      return state.set('fetching', false)
		case types.FETCH_DISPLAY_SUCCESS:
			return state.set('displayList', action.data).set('fetching', false)
    case types.FETCH_AVAILABLE_SUCCESS:
			return state.set('availableList', action.data).set('fetching', false)	
		case types.FETCH_MY_ALL_SUCCESS: 
			return state
				.set('myBeforePayList', action.data.couponList)
				.set('fetching', false)
		case types.FETCH_ALL_COUPON_SUCCESS: 
			if (state.getIn(['myCoupon', 'list']).length == action.data.totalNum) {
				Toast.info('没有更多了', 2)
				return state 
			}
			return state
        .updateIn(['myCoupon', 'list'], list => [...list, ...action.data.couponList])
        .setIn(['myCoupon', 'total'], action.data.totalNum)
				.setIn(['myCoupon', 'page'], state.getIn(['myCoupon', 'page']) + 1)
				.set('fetching', false)
    case types.FETCH_COLLECT_SUCCESS:
			return state.set('fetching', false)
		case types.FETCH_DETAIL_SUCCESS:
			return state.set('detail', action.data).set('fetching', false)
		case types.FETCH_RETURNED_SUCCESS:
			return state.set('returnedList', action.data).set('fetching', false)
    default:
      return state
  }
}