import 'babel-polyfill';
import { fromJS } from 'immutable';
import React from 'react';
import { render } from 'react-dom';
import { match, browserHistory } from 'react-router';
import Root from './containers/Root';
import configureStore from './store/configureStore';
import route from './routes';


// React Performance Testing
// if (__DEVELOPMENT__) {
//   window.ReactPerf = require('react-addons-perf');
// }

//单页应用，默认关闭埋点自动上传
window._TRACK_AUTO_SEND = false;

const { pathname, search, hash } = window.location;
const location = `${pathname}${search}${hash}`;

// const initialState = JSON.parse(document.getElementById('initialState').innerHTML);
// const store = configureStore(browserHistory, fromJS(initialState));
const store = configureStore(browserHistory);

const routes = route(store);
match({ routes, location }, () => {
  render(
    <Root store={store} routes={routes} />,
    document.getElementById('root')
  );
});
