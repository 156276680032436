import Cookies from 'js-cookie';

export function standardPost(url, args, method) {
  const form = document.createElement('form')
  form.setAttribute('method', method)
  form.setAttribute('accept-charset', 'utf-8')
  form.setAttribute('action', url)
  for (let i in args) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', i)
    input.value = args[i]
    form.appendChild(input)
  }
  document.querySelector('body').appendChild(form)
  form.submit()
}

export function param(obj, traditional) {
  let arr = [];
  for (let key in obj) {
    arr.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return arr.join('&');
};

export function dateFormat(date, format) {
  var o = {
    'M+': date.getMonth() + 1, //month
    'd+': date.getDate(), //day
    'h+': date.getHours(), //hour
    'm+': date.getMinutes(), //minute
    's+': date.getSeconds(), //second
    'q+': Math.floor((date.getMonth() + 3) / 3), //quarter
    'S': date.getMilliseconds() //millisecond
  }

  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return format;
};

export function dateCompare(date) {
  var now = new Date().getTime();
  return date - now > 31536000000 ? 2 : date > now ? 1 : date === now ? 0 : -1;
}

//计算周岁
export function getAgeByBirthday(birthday) {
  const reg =  birthday.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
  if (!reg || reg.length !== 5) {
    return -1;
  }
  let returnAge = -1;
  const birthYear = reg[1], birthMonth = reg[3], birthDay = reg[4];
  const today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  const nowYear = today.getFullYear(),
     nowMonth = today.getMonth() + 1,
     nowDay = today.getDate();
  if (nowYear == birthYear) {
    returnAge = 0; //同年 则为0岁
  } else {
    let ageDiff = nowYear - birthYear; //年之差
    if (ageDiff > 0) {
      if (nowMonth == birthMonth) {
        let dayDiff = nowDay - birthDay; //日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        let monthDiff = nowMonth - birthMonth; //月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天
    }
  }
  return returnAge;
}
/**
 * 访问平台
 * @return {String} 微信|android|ios|pc
 */
export function platform() {
  try {
    let ua = navigator.userAgent;
    if (ua.indexOf('MicroMessenger') > -1) {
      return 5;
    } else if (ua.indexOf('Android') > -1 && (/android/.test(Cookies.get('source')) || /android/.test(Cookies.get('platform')))) {
      return 3;
    } else if ((ua.indexOf('iPhone') > -1 || ua.indexOf('iPad') > -1) && (/ios/.test(Cookies.get('source')) || /ios/.test(Cookies.get('platform')))) {
      return 4;
    } else if ((ua.indexOf('iPhone') > -1 || ua.indexOf('iPad') > -1 || ua.indexOf('Android') > -1) && !/android|ios/.test(Cookies.get('source')) && !/android|ios/.test(Cookies.get('platform'))) {
      return 2;
    } else {
      return 1;
    }
  } catch (e) {
    console.log(e)
  }
}

// 预防cookie消失，客户端判断
// 当前平台 0iOS 1安卓 2微信 3H5
export function getPlatform() {
  try {
    if (/MicroMessenger/i.test(navigator.userAgent)) {
      return 2;
    } else if (/ios/i.test(Cookies.get('source'))) {
      return 0;
    } else if (/android/i.test(Cookies.get('source'))) {
      return 1;
    } else {
      return 3;
    }
  } catch (e) {
    console.log(e)
  }
}

// 0支付宝，1微信
export function getPayType() {
  try {
    if (/MicroMessenger/i.test(navigator.userAgent)) {
      return 1;
    }
    return 0;
  } catch (e) {
    console.log(e)
  }
}

// decode base64
export function decodeBase64(str = '') {
  return decodeURIComponent(escape(window.atob(str)));
  // if (__CLIENT__) {
  //   return decodeURIComponent(escape(window.atob(str)));
  // } else {
  //   return new Buffer(str, 'base64').toString('utf-8');
  // }
}

// encode baseb4
export function encodeBase64(str = '') {
  return window.btoa(unescape(encodeURIComponent(str)));
  // if (__CLIENT__) {
  //   return window.btoa(unescape(encodeURIComponent(str)));
  // } else {
  //   return new Buffer(str).toString('base64');
  // }
}

// 身份证验证
export function isCertificateNo(str) {
   return /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/i.test(str);
}

// 手机号验证
export function isPhoneNo(str) {
  return /^1\d{10}$/.test(str);
}

// 邮箱地址验证
export function isEmail(str){
  // return /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(str);
  return /^[A-Za-z\d][._-\w]*@[A-Za-z\d]+(\.[A-Za-z\d]+)+$/.test(str)
}

// 邮编号码验证
export function isPostCode(str){
  return /\d{6}/.test(str);
}

// 加腾讯云图片参数
export function appendOptionParams (param, string) {
  const optionParam = '?imageMogr2/quality/80/format/' + param;
  return string.replace(/(https?\:\/\/[\w\-.\/]+image\.myqcloud\.com[\w\-.\/]+[^\>\"\/])/g, (rs, $1) => {
    return (!/\?/.test(rs)) ? ($1 + optionParam) : rs;
  })
}
export function appendQueryParam (url, param, value) {
  return !value
           ? url
             : (url.match(param)
               ? url
                 : `${url}${/\?/.test(url) ? '&' : '?'}${param}=${value}`
             )
}

// 获取小数计算精确值
export function precision(num, precision = 12) {
  return parseFloat(num.toPrecision(precision));
}

// 调用app端分享功能
export function appShare() {
  const deviceType = /(iPhone|iPad)/.test(navigator.userAgent) ? 'IOS' : (/(Android)/.test(navigator.userAgent) ? 'ANDROID' : 'PC')
  if (deviceType == 'ANDROID') {
    window.localjs && window.localjs.invokeAppShare && window.localjs.invokeAppShare()
  } else if (deviceType == 'IOS') {
    window.app && window.app.jsNavtiveHandle && window.app.jsNavtiveHandle("{\"targetClass\":\"\",\"methodType\":\"0\",\"methodName\":\"invokeAppShare\",\"parm\":[]}")
  }
}

// 更改url里面的参数值
export function changeURLArg(url, arg, val){ 
  var pattern = arg + '=([^&]*)'; 
  var replaceText = arg + '=' + val; 
  if ( url.match(pattern) ){ 
      var tmp = '/(' + arg + '=)([^&]*)/gi'; 
      tmp=url.replace(eval(tmp),replaceText); 
      return tmp; 
  } else { 
      if (url.match('[\?]')){ 
        return url + '&'+ replaceText; 
      } else { 
        return url +'?' + replaceText; 
      } 
  } 
  return url + '\n' + arg + '\n' + val; 
} 

// 过滤用户输入的多余空格
export function rmSpace(val) {
  return val.replace(/\s*/g, '')
}

// 规范金额
export function formatMoney(money, split = true) {
  if (!money) {
    return '0.00'
  }
  let sign = (money + '').split('')[0]
  if (sign != '-' && sign != '+') {
    sign = ''
  } else {
    money = Math.abs(money)
  }
  if (split) {
    return (money / 100).toFixed(2)
  }
  let arr = (Math.floor(money) + '').split('')
  arr.length === 1 && arr.splice(-1, 0, '0')
  arr.length >= 2 && arr.splice(-2, 0, '.')
  arr.length === 3 && arr.splice(-3, 0, '0')
  arr.length > 6 && arr.splice(-6, 0, ',')
  arr.length > 10 && arr.splice(-10, 0, ',')
  let output = arr.join('')
  if (sign != '') {
    output = sign + output
  }
  return output
}

export function isWechat() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('micromessenger') != -1) { //微信端
    return true
  }
  return false
}

// 取query
export function getQueryString(search, name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = search.substr(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}

/**
* 判断当前是否是闰年
* @param { Number } year 年数
*/
export function isLeapYear(year) {
	// 条件1：年份必须要能被4整除
	const cond1 = year % 4 === 0
	// 条件2：年份不能是整百数
	const cond2 = year % 100 !== 0
	// 条件3：年份是400的倍数
	const cond3 = year % 400 === 0
	// 当条件1和条件2同时成立时，就肯定是闰年，所以条件1和条件2之间为“与”的关系。
	// 如果条件1和条件2不能同时成立，但如果条件3能成立，则仍然是闰年。所以条件3与前2项为“或”的关系。
	// 所以得出判断闰年的表达式：
	const cond = cond1 && cond2 || cond3
	// 如果是闰年
	if (cond) {
		return true
	}
	return false
}

let oldTime = ''
export function preventClick(gap = 3000) {
  if( oldTime=='' ){
    oldTime = new Date().getTime();
		return true;
	}else{
		const newTime = new Date().getTime();
		if(newTime - oldTime > gap){
      oldTime = new Date().getTime();
			return true;
		}else{
			return false;
		}
	}
}