import { Map, List } from 'immutable';
import {
  ACTIVITY_QUALIFY_REQUEST,
  ACTIVITY_QUALIFY_FAILURE,
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_RESET,
  ORDER_LIST_SUCCESS,
  ORDER_DETAIL_SUCCESS,
  ORDER_CREATE_SUCCESS,
  ORDER_CANCEL_SUCCESS,
  ORDER_SUCCESS_PRODUCT,
  CLAIM_OTAIN_REQUEST,
  CLAIM_OTAIN_SUCCESS,
  CLAIM_OTAIN_FAIL,
  REQUEST_CLAIM_DETAIL,
  CLAIM_DETAIL_FAILURE,
  CLAIM_DETAIL_SUCCESS,
  DELETE_APPLY_CLAIM,
  SUBMIT_CAR_INSURANCE_ORDER_LIST,
  CAR_INSURANCE_ORDER_LIST_SUCCESS,
  CAR_INSURANCE_ORDER_LIST_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  CHECK_BINDING_QUALIFY,
  CHECK_BINDING_QUALIFY_SUCCESS,
  CHECK_BINDING_QUALIFY_FAILURE,
  APPLY_CLAIM_REQUEST,
  APPLY_CLAIM_SUCCESS,
  APPLY_CLAIM_FAIL,
  APPLY_CLAIM_CMS_SUCCESS,
  TAIKANG_ONLINE as type,
} from '../constants/actionTypes';
// import { types } from 'util';

const initialState = Map({
  isFetching: false,
  currenClickStatus: 0, // 全部订单
  dialogType: '',
  currentOrderId: '',
  orderList: null,
  detailList: Map(),
  detail: null,
  applyClaim: Map(),
  claimDetail: Map(),
  carInsurOrderList: Map(),
  bindingQualify: Map(),
  applyClaimConfig: {}
});

export default function order(state = initialState, action) {
  switch (action.type) {
    case ORDER_FETCH_REQUEST:
    case CLAIM_OTAIN_REQUEST:
    case REQUEST_CLAIM_DETAIL:
    case SUBMIT_CAR_INSURANCE_ORDER_LIST:
    case DELETE_ORDER_REQUEST:
      // 理赔接口请求开始
    case ACTIVITY_QUALIFY_REQUEST:
    case CHECK_BINDING_QUALIFY:
    case APPLY_CLAIM_REQUEST:
    case type.FETCH_SIGNURL: 
    case type.FETCH_STATUS:
      return state.merge({ isFetching: true });
    case ORDER_FETCH_RESET:
      // 理赔请求开始
      return state.merge({ isFetching: false });
    case CLAIM_DETAIL_SUCCESS:
      return state.merge({isFetching: false, claimDetail: action.data})
    case ORDER_LIST_SUCCESS:
      // 订单列表(不包含详情)
      return state.merge({ isFetching: false, orderList: action.data });
    case CLAIM_OTAIN_SUCCESS:
      // 理赔接口成功
      return state.merge({isFetching: false, applyClaim: action.data});
    case DELETE_APPLY_CLAIM:
      // 删除理赔信息
      return state.merge({isFetching: false, claimDetail: {}});
    case ORDER_DETAIL_SUCCESS:
      return state.merge({ isFetching: false }).mergeIn(['detailList', action.orderId], action.data);
    case CAR_INSURANCE_ORDER_LIST_SUCCESS:
      // 车险订单列表页
      return state.mergeDeep({ isFetching: false, carInsurOrderList: { 'list' : action.data}});
    case ORDER_CREATE_SUCCESS:
      //订单创建成功
      return state.merge({ isFetching: false });

    case ORDER_SUCCESS_PRODUCT:
      //订单成功页的商品列表
      return state.merge({ isFetching: false }).merge(action.data);
    
     case ACTIVITY_QUALIFY_FAILURE:
      //主商品活动资格号请求失败
     case CLAIM_OTAIN_FAIL:
     case CLAIM_DETAIL_FAILURE:
     case CAR_INSURANCE_ORDER_LIST_FAILURE:
     case DELETE_ORDER_FAILURE:
     case DELETE_ORDER_SUCCESS:
      // 理赔接口失败
      return state.merge({isFetching: false});
    case ORDER_CANCEL_SUCCESS:
      //订单取消成功
      let nextState = state.merge({isFetching: false})
        .updateIn(['orderList'], list => list.update(
          list.findIndex(ele => ele.get('orderId') == action.orderId),
          ele => ele.merge({orderStatus: 3})));
      if (nextState.getIn(['detailList', action.orderId])) {
        nextState = nextState.updateIn(['detailList', action.orderId], ele => ele.merge({orderStatus: 3}));
      }
      return nextState;
    case CHECK_BINDING_QUALIFY_SUCCESS:
      return state.merge({isFetching: false}).mergeIn(['bindingQualify'], action.data);
    case APPLY_CLAIM_CMS_SUCCESS:
      return state.merge({isFetching: false, applyClaimConfig: action.data})
    case APPLY_CLAIM_SUCCESS:
    case APPLY_CLAIM_FAIL:
    case type.FETCH_SIGNURL_SUCCESS:
    case type.FETCH_STATUS_SUCCESS:
    case type.FETCH_SIGNURL_FAILURE:
    case type.FETCH_STATUS_FAILURE:
      return state.merge({isFetching: false});
    default:
      return state;
  }
}
