import { PASSPORT_LOGIN, CASHIER_QUERY } from '../../constants/api';

//登录跳转
export function redirectPassport() {
  window && (window.location.href = `${PASSPORT_LOGIN}?cmd=login&referer=${encodeURIComponent(window.location.href)}`);
}

//跳转收银台支付
export function redirectCashier(partnerId, orderId) {
  if (/MicroMessenger/i.test(navigator.userAgent)) {
    // 微信中, 获取openId,拉起收银台
    let redirectUrl = `http://wxredirect.haiziwang.com/query/${orderId}?partnerid=${partnerId}`;
    if (getRootDomain().indexOf('cekid') !== -1) {
      redirectUrl = `http://wxredirect.haiziwang.com/cekid/query/${orderId}?partnerid=${partnerId}`;
    }
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx834e5f8d388014a0&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`;
  } else {
    // 其他情况, 直接拉起收银台
    window.location.href = `${CASHIER_QUERY}/${orderId}?cmd=kwcashier&partnerid=${partnerId}`;
  }
}
