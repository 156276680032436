import React, { Component, PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as indexActions from '../actions'
import '../common/css/main.css'
import '../common/css/reset_antd.css'

class App extends Component {
  componentDidMount() {
    const { location, actions } = this.props;
    const { itemId } = this.props.params
    // 人客合一分享支持
    const keys = location.query.hserecomkey
    if (keys instanceof Array) {
      actions.saveRecomKey(keys[keys.length-1], itemId)
    } else {
      actions.saveRecomKey(keys, itemId)
    }
    // sentry monitoring
    // window.Raven && window.Raven.config('https://14778f48234e43288e11b8a5f7735bc5@sentry.io/96608').install();
  }

  render() {
    const { children } = this.props;
    return (
      <div className="wrapper">
        {children}
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(indexActions, dispatch)
});

export default connect(() => ({}), mapDispatchToProps)(App);
