// 当前domain
const __CLIENT__ = (() => document.domain.includes('test') ? false : true)()

export const ROOT_DOMAIN =`.${document.domain.split('.').slice(-2).join('.')}`;
  // export const ROOT_DOMAIN = __CLIENT__ ?
  // `.${document.domain.split('.').slice(-2).join('.')}` :
  // '.haiziwang.com';

// 帐户登录
export const PASSPORT_LOGIN = `//passport${ROOT_DOMAIN}/passport/login`;

/** USER 服务 **/
// 判断用户是否登录
export const USER_CHECK_LOGIN = `//user${ROOT_DOMAIN}/user/CheckLogin`;

/** CMS 服务 **/
export const CMS_SERVER = `//cms${ROOT_DOMAIN}`;
export const CMS_SERVER_NEIBU = `//cmsneibu.haiziwang.com`;
// 保险首页CMS配置
export const CMS_BAO_XIAN_INDEX = `${CMS_SERVER}/publish/989/index.json`;
// 保险首页推荐产品与精品测试环境接口
export const CMS_BAO_XIAN_MAIN = `${CMS_SERVER}/publish/989/main.json`;
// 保险首页推荐产品与精品线上接口
export const CMS_NEIBU_BAO_XIAN_MAIN = `http:${CMS_SERVER_NEIBU}/publish/989/main.json`;
// 保险首页CMS配置，内部服务端加载使用
export const CMS_NEIBU_BAO_XIAN_INDEX = `http:${CMS_SERVER_NEIBU}/publish/989/index.json`;
// 保险频道页面CMS配置
export const CMS_BAO_XIAN_CHANNEL = `${CMS_SERVER}/publish/989/channel`;
// 保险频道页面CMS配置，内部服务端加载使用
export const CMS_NEIBU_BAO_XIAN_CHANNEL = `http:${CMS_SERVER_NEIBU}/publish/989/channel`;
// 购买成功推荐商品
export const CMS_ORDER_SUCCESS_PRODUCT = `${CMS_SERVER}/publish/989/gmcg_tj_goodslist.json`;
// 城市顾问配置
export const CMS_CITY_ADVISER = `${CMS_SERVER}/publish/989/city_adviser.json`;
export const CMS_NEIBU_CITY_ADVISER = `http:${CMS_SERVER_NEIBU}/publish/989/city_adviser.json`;
// 三级地址信息
export const ST_DATA_ADDRESS = `//st.haiziwang.com/data/front/address.json`;
// 查询归属地
export const IP_SEARCH_DETAIL = `//ipsearch${ROOT_DOMAIN}/ipsearch-web/ipDetail/queryIpDetail.do`;
// 收银台
export const CASHIER_QUERY = `//cashier${ROOT_DOMAIN}/query`

/** BAOXIAN 服务 **/
export const BAO_XIAN_SERVER = __CLIENT__ ? `//baoxian${ROOT_DOMAIN}` : `http://baoxian${ROOT_DOMAIN}`;
// 保险分享用户查询
export const BAO_XIAN_MEMBER_QUERY = `${BAO_XIAN_SERVER}/front_insurance/bonus/queryMemberId.do`;
// 保险产品列表
export const BAO_XIAN_PRODUCT_LIST = `${BAO_XIAN_SERVER}/front_insurance/goods/queryAllGoodsList.do`;
// 保险产品列表, 内部服务端加载使用
export const BAO_XIAN_NEIBU_PRODUCT_LIST = `http://baoxianneibu.haiziwang.com/front_insurance/goods/queryAllGoodsList.do`;
// 保险商祥接口
export const BAO_XIAN_PRODUCT_DETAIL = `${BAO_XIAN_SERVER}/front_insurance/frontgoods/loadProductAllInfo.do`;
// 保险分享佣金hserecomkey获取接口
export const SHAREKEY_SERVER = __CLIENT__ ? `//activity` : `http://active`;
// export const SHAREKEY_SERVER = __CLIENT__ ? `//backneibu` : `//test.share`
// export const SHAREKEY_SERVER = __DEVELOPMENT__ ? `//test.share` : '//backneibu'
export const BAO_XIAN_SHARE_KEY =  `${SHAREKEY_SERVER}.cekid.com/activity/commercialInterface/getKey.do`;
// export const BAO_XIAN_SHARE_KEY = `${SHAREKEY_SERVER}.haiziwang.com/share/commercialInterface/getKey.do`
// 主商品是否下架隐藏
export const BAO_XIAN_PRODUCT_STATUS = `${BAO_XIAN_SERVER}/front_insurance/frontgoods/checkStatus.do`;
// 主商品活动信息查询
export const BAO_XIAN_ACTIVITY = `${BAO_XIAN_SERVER}/front_insurance/seckill/querySecKillActivity.do`;
// 小雨伞长期限试算接口
export const UMBRELLA_RESTRICT = `${BAO_XIAN_SERVER}/front_insurance/xiaoyusan/calInsPrice.do`;
// 主商品活动信息查询
export const BAO_XIAN_ACTIVITY_QUALIFY = `${BAO_XIAN_SERVER}/front_insurance/seckill/getSecKillQualification.do`;
// 商详活动接口
export const BAO_XIAN_CLAUSE_INFO = `${BAO_XIAN_SERVER}/front_insurance/frontgoods/loadInsuranceClauseInfo.do`;
// 查询健康告知与职业工种告知信息
export const BAO_XIAN_QUERY_NOTICE = `${BAO_XIAN_SERVER}/front_insurance/frontgoods/loadTbgz.do`;
// 投保信息接口
export const BAO_XIAN_INSURANCE_INFO = `${BAO_XIAN_SERVER}/front_insurance/orderInte/queryInsureData.do`;
// 常用被保人查询
export const BAO_XIAN_QUERY_RECOGNIZEE_LIST = `${BAO_XIAN_SERVER}/front_insurance/orderInte/queryRecognizeeList.do`;
// 新增修改被保人信息
export const BAO_XIAN_SUBMIT_RECOGNIZEE_INFO = `${BAO_XIAN_SERVER}/front_insurance/orderInte/saveOrUpdateFrequentlyUsedRecognizee.do`;
// 教育金计算
export const BAO_XIAN_EDUCATION_CALCULATE = `${BAO_XIAN_SERVER}/front_insurance/frontjyj/calcPayPrice.do`;
// 年龄验证
export const BAO_XIAN_BIRTHDAY_VALIDATE = `${BAO_XIAN_SERVER}/front_insurance/frontjyj/validateBirthday.do`;
// 保险订单创建
export const BAO_XIAN_ORDER_CREATE = `${BAO_XIAN_SERVER}/front_insurance/genorder/genInsOrder.do`;
// 保险订单列表
export const BAO_XIAN_ORDER_LIST = `${BAO_XIAN_SERVER}/front_insurance/orderInfo/queryOrderList.do`;
// 保险删除订单
export const BAO_XIAN_DELETE_ORDER = `${BAO_XIAN_SERVER}/front_insurance/orderInfo/deleteOrder.do`
// 保险订单详情
export const BAO_XIAN_ORDER_DETAIL = `${BAO_XIAN_SERVER}/front_insurance/orderInfo/queryOrderDetail.do`;
// 保险订单支付
export const BAO_XIAN_ORDER_PAY = `${BAO_XIAN_SERVER}/front_insurance/orderInfo/orderPay.do`;
// 保险订单退保
export const BAO_XIAN_ORDER_REFUND = `${BAO_XIAN_SERVER}/front_insurance/orderInfo/refundOrder.do`;
// 保险订单取消
export const BAO_XIAN_ORDER_CANCEL = `${BAO_XIAN_SERVER}/front_insurance/orderInfo/orderCancel.do`;
// 保险订单评价
export const BAO_XIAN_ORDER_COMMENT = `${BAO_XIAN_SERVER}/front_insurance/orderInfo/updateOrderComment.do`;
// 保险城市选择查询
export const BAO_XIAN_QUERY_LAST_LOCATION = `${BAO_XIAN_SERVER}/front_insurance/consultant/queryLastLocation.do`;
// 保险城市选择保存
export const BAO_XIAN_SAVE_LAST_LOCATION = `${BAO_XIAN_SERVER}/front_insurance/consultant/saveLocationHistory.do`;
// 保险顾问预约
export const BAO_XIAN_SAVE_RESERVATION = `${BAO_XIAN_SERVER}/front_insurance/consultant/saveReservation.do`;
// 评论查询
export const COMMENT_QUERY = `//cmt${ROOT_DOMAIN}/v1/finance/comment/search/object`;
// 续保与否查询
export const BAO_XIAN_ORDER_RENEWAL = `${BAO_XIAN_SERVER}/front_insurance/orderInfo/queryHealthFlag.do`;
// 续保页面信息
export const BAO_XIAN_ORDER_RENEW_INFO = `${BAO_XIAN_SERVER}/front_insurance/orderInfo/queryRenewalInsurance.do`;
// 理赔申请资料提交
export const CLAIM_OBTAIN_SUBMIT = `${BAO_XIAN_SERVER}/front_insurance/claim/submitClaimInfo.do`;
// 理赔申请图片上传
export const CLAIM_OBTAIN_PIC_UPLOAD = `${BAO_XIAN_SERVER}/front_insurance/claim/uploadImg.do`;
// 电话理赔查询
export const API_FETCH_CLAIM_DETAIL = `${BAO_XIAN_SERVER}/front_insurance/claim/detail.do`;
// 小雨伞长期险省市区和职业工作的接口
export const XIAOYUSAN_INSURANCE_INFO = `${BAO_XIAN_SERVER}/front_insurance/xiaoyusan/getJobAndAreaList.do`;
// 车险保险公司资料
export const CAR_INSURANCE_COMPANY = `${BAO_XIAN_SERVER}/front_insurance/auto/getAutoAreaAndCompany.do`;
// 保险车牌号是否投保过
export const CHECK_CAR_ID = `${BAO_XIAN_SERVER}/front_insurance/auto/validateAutoOrder.do`;
// 提交车险订单接口
export const CAR_INSURANCE_ORDER = `${BAO_XIAN_SERVER}/front_insurance/auto/createAutoOrder.do`;
// 车险订单列表
export const CAR_INSURANCE_ORDER_LIST = `${BAO_XIAN_SERVER}/front_insurance/auto/queryAutoOrderList.do`;
//
export const CHECK_PLATENUMBER_STATUS = `${BAO_XIAN_SERVER}/front_insurance/auto/validatePlateNumber.do`;
// 投保页面职业工作,被保人与投保人公用
export const INSURE_AND_RECOGNIZEE_JOBS = `${BAO_XIAN_SERVER}/front_insurance/orderInte/insureJobList.do`;
// 投保页面的省市区,被保人与投保人公用
export const INSURE_AND_RECOGNIZEE_AREA = `${BAO_XIAN_SERVER}/front_insurance/orderInte/insureAreaList.do`;

// 收货保障
// 初始化查询商品等信息
export const API_DELIVERY_SAFEGUARD = {
  ORDER_INFO: `${BAO_XIAN_SERVER}/front_insurance/recipient/orderInfo.do`,
  CMS_CONFIG: '//cms.cekid.com/publish/989/config/safeguard.json',
  APPLY_INIT: `${BAO_XIAN_SERVER}/front_insurance/recipient/claimInit.do`,
  CACU_APPLY_AMOUT: `${BAO_XIAN_SERVER}/front_insurance/recipient/cacuClaimAmout.do`,
  SUBMIT: `${BAO_XIAN_SERVER}/front_insurance/recipient/claimSubmit.do`,
}

export const BINDING_SERVER =  __CLIENT__ ? '//' : 'http://';
// 检测捆绑销售是否有购买资格
export const INSURANCE_BINDING_STATUS = `${BINDING_SERVER}baoxian.cekid.com/front_insurance/genorder/bindingForSale.do`;
// 下单成功后获取购买资格
export const INSURANCE_BINDING_QUALIFY = `${BINDING_SERVER}baoxian.cekid.com/front_insurance/genorder/saleList.do`;

// 运费险
export const FRIEGHT_INSURANCE_API = '//baoxian.cekid.com/front_insurance/freight/queryOrderLog.do';
export const FRIEGHT_CMS_API = '//cms.cekid.com/publish/989/config/freight.json'

// 银行列表
export const FETCH_BANKLIST = `${BAO_XIAN_SERVER}/front_insurance/orderInte/bankList.do`

// 申请赔付CMS配置
export const APPLY_CLAIM_CONFIG = `//cms.cekid.com/publish/989/config/apply/claim.json`
// 申请赔付申请资料
export const APPLY_CLAIM_SUBMIT = `//baoxian.cekid.com/front_insurance/indemnity/submitInfo.do`

// 阳光保险重疾险
export const SUNSHINE_INSURANCE = {
  AREA_LIST: `//baoxian.cekid.com/front_insurance/orderInte/insureAreaList.do`, //可购买地区
  CRITIAL_DISEASE_ESTIMATE_PRICE: `//baoxian.cekid.com/front_insurance/sunshinelong/payCost.do`, //试算
  SUNSHINE_REDIRECT: `//cms.cekid.com/publish/989/config/sunshineUrl.json`
}

// 我的券
export const MY_COUPON = {
  DISPLAY: `${BAO_XIAN_SERVER}/front_insurance/coupon/revealCoupon.do`, // 可领取的
  AVAILABLE: `${BAO_XIAN_SERVER}/front_insurance/coupon/receiveCouponList.do`, // 用户可领取的
  COLLECT: `${BAO_XIAN_SERVER}/front_insurance/coupon/collectImmediately.do`, //领取
  MY_ALL: `${BAO_XIAN_SERVER}/front_insurance/coupon/couponList.do`, // 我的全部
  DETAIL: `${BAO_XIAN_SERVER}/front_insurance/coupon/couponDetail.do`, //单个详情
  RETURNED: `${BAO_XIAN_SERVER}/front_insurance/coupon/queryReturnCouponList.do`, // 购买成功后返回的
}

// 泰康微信代扣
export const TAIKANG_ONLINE_API = {
  SIGNURL: `${BAO_XIAN_SERVER}/front_insurance/taikangCallBack/getSignUrl.do`,
  CALLBACK_STATUS: `${BAO_XIAN_SERVER}/front_insurance/taikangCallBack/queryOrderStatus.do`,
  CONFIRM_SIGN: `${BAO_XIAN_SERVER}/front_insurance/taikangCallBack/signOkInsure.do`,
}

// 通过uid查工号
export const QUERY_RECOMMEND_NO = `${BAO_XIAN_SERVER}/front_insurance/employee/getEmpRefInfo.do`

// 查询是否为锁定期
const XING_BEI_SERVER = '//insrw.xingbeizaixian.com'
export const MAINTAIN_STATUS = `${XING_BEI_SERVER}/share/insShare.do`

// 查询需要切换的产品id
export const QUERY_SWITCH_ID = 'https://cms.cekid.com/publish/989/switch/product.json'


//查询pc首页信息
export const QUERY_NEWPC_INFO='https://cms.cekid.com/publish/989/official-website.json'
