import React, { Component, PropTypes } from 'react';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

/* Create enhanced history object for router */
const createSelectLocationState = () => {
  let prevRoutingState, prevRoutingStateJS;
  return (state) => {
    const routingState = state.get('routing'); // or state.routing
    if (typeof prevRoutingState === 'undefined' || prevRoutingState !== routingState) {
      prevRoutingState = routingState;
      prevRoutingStateJS = routingState.toJS();
    }
    return prevRoutingStateJS;
  };
};

export default class Root extends Component {
  render() {
    const { store, routes } = this.props;
    const history = syncHistoryWithStore(browserHistory, store, {
      selectLocationState: createSelectLocationState()
    });
    // if (__DEVELOPMENT__ && __CLIENT__ && __DEVTOOLS__ && !window.devToolsExtension) {
    //   const DevTools = require('./DevTools').default;
    //   return (
    //     <Provider store={ store }>
    //       <Router routes={ routes } history={ history } />
    //       <DevTools />
    //     </Provider>
    //   );
    // }

    return (
      <Provider store={ store }>
        <Router routes={ routes } history={ history } />
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  routes: PropTypes.object.isRequired
};
