import { Map, List, fromJS } from 'immutable';
import {
  PRODUCT_DATA_REQUEST,
  PRODUCT_FETCH_REQUEST,
  PRODUCT_FETCH_RESET,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_DETAIL_REQUEST,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_DETAIL_FAILURE,
  PRODUCT_STATUS_FAILURE,
  INSURANCE_SHAREKEY_REQUEST,
  INSURANCE_SHAREKEY_SUCCESS,
  INSURANCE_SHAREKEY_FAILURE,
  ACTIVITY_REQUEST,
  ACTIVITY_SUCCESS,
  ACTIVITY_FAILURE,
  PRODUCT_ACTIVITY_FAILURE_DETAIL_SUCCESS,
  PRODUCT_NOTICE_SUCCESS,
  CLAUSE_LIST_REQUEST,
  CLAUSE_LIST_SUCCESS,
  CLAUSE_LIST_FAILURE,
  INSURANCE_INFO_SUCCESS,
  INSURANT_JOBNO_SUCCESS,
  INSURANT_AREAINFO_SUCCESS,
  RECOGNIZEE_LIST_SUCCESS,
  RECOGNIZEE_UPDATE_SUCCESS,
  RECOGNIZEE_UPDATE_FAILURE,
  COMMENT_QUERY_REQUEST,
  COMMENT_QUERY_SUCCESS,
  COMMENT_QUERY_FAILURE,
  QUERY_RENEW_SUCCESS,
  RESTRICT_REQUEST,
  RESTRICT_FAILURE,
  UMBRELLA_RESTRICT_REQUEST,
  UMBRELLA_RESTRICT_SUCCESS,
  UMBRELLA_RESTRICT_FAILURE,
  FETCH_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  XIAOYUSAN_INFO_REQUEST,
  XIAOYUSAN_INFO_SUCCESS,
  XIAOYUSAN_INFO_FAILURE,
  FETCH_CAR_INSURANCE,
  CAR_INSURANCE_COMP_INFO_SUCCESS,
  CAR_INSURANCE_COMP_INFO_FAILURE,
  SUBMIT_INSURANCE_ORDER,
  CAR_INSURANCE_ORDER_SUCCESS,
  CAR_INSURANCE_ORDER_FAILURE,
  CHECK_PLATE_NUMBER,
  CHECK_PLATE_NUMBER_SUCCESS,
  CHECK_PLATE_NUMBER_FAILURE,
  INSURE_AND_RECOGNIZEE_INDUSTRY_SUCCESS,
  CHECK_BINDING_STATUS,
  CHECK_BINDING_STATUS_SUCCESS,
  CHECK_BINDING_STATUS_FAILURE,
  FETCH_BANKLIST_REQUEST, 
  FETCH_BANKLIST_SUCCESS, 
  SUNSHINE_AREA_LIST_REQUEST,
  SUNSHINE_AREA_LIST_SUCCESS,
  SUNSHINE_AREA_LIST_FAILURE,
  SUNSHINE_ESTIMATE_PRICE_REQUEST,
  SUNSHINE_ESTIMATE_PRICE_SUCCESS,
  SUNSHINE_ESTIMATE_PRICE_FAILURE,
  SUNSHINE_REDIRECT_REQUEST, 
  SUNSHINE_REDIRECT_SUCCESS, 
  SUNSHINE_REDIRECT_FAILURE,
  MAINTAIN_STATUS_REQUEST,
  MAINTAIN_STATUS_SUCCESS,
  MAINTAIN_STATUS_FAILURE,
  SWITCH_ITEMS_REQUEST, SWITCH_ITEMS_SUCCESS, SWITCH_ITEMS_FAILURE
} from '../constants/actionTypes';

const initialState = Map({
  isFetching: false,
  categories: List(),
  goods: List(),
  list: Map(),
  insureInfo: Map(),
  renewInfo: Map(),
  notice: Map(),
  umbrella: Map(),
  job_and_industry: Map(),
  carInsurance: Map(),
  hserecomkey: '',
  binding: Map(),
  bankList: List()
});

export default function product(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAR_INSURANCE:
    case PRODUCT_FETCH_REQUEST:
    case PRODUCT_DATA_REQUEST:
    case PRODUCT_DETAIL_REQUEST:
    case INSURANCE_SHAREKEY_REQUEST: 
    case CLAUSE_LIST_REQUEST:
    case RESTRICT_REQUEST:
    case ACTIVITY_REQUEST:
    case FETCH_LOGIN:
    case UMBRELLA_RESTRICT_REQUEST:
    case XIAOYUSAN_INFO_REQUEST:
    case SUBMIT_INSURANCE_ORDER:
    case CHECK_PLATE_NUMBER:
    case CHECK_BINDING_STATUS:
    case FETCH_BANKLIST_REQUEST:
    case SUNSHINE_AREA_LIST_REQUEST:
    case SUNSHINE_ESTIMATE_PRICE_REQUEST:
    case SUNSHINE_REDIRECT_REQUEST:
    case MAINTAIN_STATUS_REQUEST:
    case SWITCH_ITEMS_REQUEST:
    // 数据请求中
      return state.merge({ isFetching: true });
    
    case PRODUCT_FETCH_RESET:
    case LOGIN_SUCCESS:
    case SUNSHINE_AREA_LIST_SUCCESS:
    case SUNSHINE_REDIRECT_SUCCESS:
    case MAINTAIN_STATUS_SUCCESS:
      return state.merge({ isFetching: false });
    case SUNSHINE_ESTIMATE_PRICE_SUCCESS:
      sessionStorage.setItem('priceFlag', 1)
      return state.merge({ isFetching: false });
    case PRODUCT_LIST_SUCCESS:
      return state.merge(action.data);

    case PRODUCT_DETAIL_SUCCESS:
      // 商品详情
      return state.merge({isFetching: false}).mergeIn(['list', action.itemId], action.data);
    
    case INSURANCE_SHAREKEY_SUCCESS:
      // return state.merge({isFetching: false}).set('hserecomkey', 'testkey');
      return state.merge({isFetching: false}).set('hserecomkey', action.data);
    case ACTIVITY_SUCCESS:
      // 商品活动接口
      return state.merge({isFetching: false}).mergeIn(['list', action.itemId, 'activity'], action.data);
    case UMBRELLA_RESTRICT_SUCCESS:
      // 小雨伞试算接口
      sessionStorage.setItem('priceFlag', 1)
      return state.merge({isFetching: false}).mergeIn(['umbrella'], action.data);
    case XIAOYUSAN_INFO_SUCCESS:
      // 小雨伞长期保省市区，职业工种接口
      return state.merge({isFetching: false}).mergeIn(['insureInfo', action.skuId, 'umbrella'], action.data);
    case INSURE_AND_RECOGNIZEE_INDUSTRY_SUCCESS:
      // 小雨伞长期保省市区，职业工种接口
      return state.merge({isFetching: false}).mergeIn(['job_and_industry'], action.data);
    case PRODUCT_ACTIVITY_FAILURE_DETAIL_SUCCESS:
      return state.merge({isFetching: false}).mergeIn(['list', action.itemId], action.data);

    case CLAUSE_LIST_SUCCESS:
      // 条款加载
      return state.merge({isFetching: false}).mergeIn(['list', action.itemId, 'clause'], action.data);
    case CAR_INSURANCE_ORDER_SUCCESS:
    case CHECK_PLATE_NUMBER_SUCCESS:
      // 订单提交成功
      return state.merge({isFetching: false});
    case CAR_INSURANCE_COMP_INFO_SUCCESS:
      // 车险信息接口
      return state.merge({isFetching: false}).mergeIn(['carInsurance'], action.data);

    case INSURANCE_INFO_SUCCESS:
      //投保信息
      return state.merge({isFetching: true}).mergeIn(['insureInfo', action.skuId], action.data);
    
    case QUERY_RENEW_SUCCESS:
      // 续保投保页
      return state.merge({isFetching: false}).mergeIn(['renewInfo', action.skuId], action.data);

    case PRODUCT_NOTICE_SUCCESS:
      //告知
      return state.merge({isFetching: false}).mergeIn(['notice', action.skuId], action.data);

    case RECOGNIZEE_LIST_SUCCESS:
      //被保人列表,
      return state.merge({isFetching: true}).mergeIn(['insureInfo', action.skuId], action.data);

    case INSURANT_JOBNO_SUCCESS:
      //投保人职业工种选择
      return state.merge({isFetching: false}).mergeIn(['insureInfo', action.skuId], action.data);
    
    case INSURANT_AREAINFO_SUCCESS:
      //长期保被保人地区选择
      return state.merge({isFetching: false}).mergeIn(['insureInfo', action.skuId], action.data);
    
    case RECOGNIZEE_UPDATE_SUCCESS:
      //保存被保人信息
      return state.merge({isFetching: false}).updateIn(['insureInfo', action.skuId, 'commonRecognizeeInfoList'], List(), ele => {
        const index = ele.findIndex(value => value.get('id') == action.data.recognizeeId);
        if (index !== -1) {
          return ele.slice(index, 1, Map(action.recognizeeInfo));
        } else {
          action.recognizeeInfo.id = action.data.recognizeeId;
          return ele.push(Map(action.recognizeeInfo));
        }
      });

    case COMMENT_QUERY_REQUEST:
      //评论
      return state.setIn(['list', action.itemId, 'comment', action.commentType, 'isFetching'], true);

    case COMMENT_QUERY_SUCCESS:
      //评论
      return state.updateIn(['list', action.itemId, 'comment'], (comment = Map()) => {
        const data = {
          count: action.data.count,
          list: action.data.list || [],
          isFetching: false
        };
        switch (action.commentType) {
          case 'brief':   // 商祥页的2条评论
            return comment.merge({ brief: data });
          case 'all':     // 所有评论
          case 'good':    // 好评
          case 'bad':     // 差评
            return comment.update(action.commentType, (typeComment = Map()) => Map({
              count: data.count,
              list: typeComment.get('list', List()).concat(fromJS(data.list)),
              isFetching: false
            }));
        }
      });
    
    case CHECK_BINDING_STATUS_SUCCESS:
      return state.merge({isFetching: false}).mergeIn(['binding'], action.data);
    
    case FETCH_BANKLIST_SUCCESS:
      return state.merge({isFetching: false}).mergeIn(['banklist'], action.data)
    case COMMENT_QUERY_FAILURE:
      //评论
      return state.mergeIn(['list', action.itemId, 'comment', action.commentType, 'isFetching'], false);

    case PRODUCT_STATUS_FAILURE:
      //主商品下架
      return state.setIn(['list', action.itemId, 'offShelf'], true);
    case ACTIVITY_FAILURE: //主商品活动请求失败
    case RECOGNIZEE_UPDATE_FAILURE:
    case CLAUSE_LIST_FAILURE:
    case RESTRICT_FAILURE:
    case PRODUCT_DETAIL_FAILURE:
    case LOGIN_FAILURE:
    case XIAOYUSAN_INFO_FAILURE:
    case CAR_INSURANCE_COMP_INFO_FAILURE:
    case CAR_INSURANCE_ORDER_FAILURE:
    case CHECK_PLATE_NUMBER_FAILURE:
    case INSURANCE_SHAREKEY_FAILURE:
    case CHECK_BINDING_STATUS_FAILURE:
    case CHECK_BINDING_STATUS_FAILURE: 
    case SUNSHINE_AREA_LIST_FAILURE:
    case SUNSHINE_REDIRECT_FAILURE:
    case MAINTAIN_STATUS_FAILURE:
    case SWITCH_ITEMS_SUCCESS:
    case SWITCH_ITEMS_FAILURE:
      return state.merge({isFetching: false});
    case UMBRELLA_RESTRICT_FAILURE:
    case SUNSHINE_ESTIMATE_PRICE_FAILURE:    
      sessionStorage.setItem('priceFlag', 0)
      return state.merge({isFetching: false});
    default:
      return state;
  }
}
