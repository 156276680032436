import { Map } from 'immutable';
import { PCINFO_FETCH_REQUEST, PCINFO_FETCH_SUCCESS, PCINFO_FETCH_FAILURE } from '../constants/actionTypes';

const initialState = {
	banner: []
};
export default function pcInfo(state = initialState, action) {
	// console.log("**************************")
	// console.log(action.type)
	// console.log("**************************")
	switch (action.type) {
		case PCINFO_FETCH_REQUEST:
			return state;
		case PCINFO_FETCH_SUCCESS:
			return {
        ...state,
        ...action.data
      }
		default:
			return state;
	}
}
